import { create } from 'zustand';

const UseCaseDataStore = create((set) => ({
  caseData: null,
  isLoading: false,
  refetch: null,
  setCaseData: (newData) => set({ caseData: newData }),
  setIsLoading: (isLoading) => set({ isLoading }),
}));

export default UseCaseDataStore;