import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Box } from '@mui/material'

const defaultGradient = {
  linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
  stops: [
    [0, Highcharts.color('#5084E9').setOpacity(0.2).get('rgba')],
    [1, Highcharts.color('#5084E9').setOpacity(0).get('rgba')]
  ]
};

const LineChart = ({categories, series, xAxis = {}, yAxis = {}, plotOptions = {}, gradient = defaultGradient, chart}) => {
  const defaultXAxisOptions = {
    categories: categories,
    gridLineWidth: 1,
    gridLineColor: '#e0e0e0',
    gridLineDashStyle: 'Dash',
    tickInterval: 1,
    tickmarkPlacement: 'on',
    startOnTick: true,
    endOnTick: true,
    labels: {
      style: {
        color: '#5084E9',
        fontWeight: 'bold'
      },
      formatter: function () {
        const date = new Date(this.value);
        const weekDay = date.getUTCDay();
        const isWeekend = weekDay === 5 || weekDay === 6;
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        if (year === 1970) return
        return `<span style="color: ${isWeekend && '#E95076'}">${month}/${day}</span>`;
      }
    },
    lineColor: '#e0e0e0',
    lineWidth: 1,
    lineDashStyle: 'Dash'
  }
  
  const defaultPlotOptions = {
    series: {
      marker: {
        enabled: true
      }
    }
  }

  const options = {
    chart: {
      type: 'area',
      ...chart
    },
    title: {
      text: '案件趨勢'
    },
    xAxis: {
      ...defaultXAxisOptions,
      ...xAxis,
      labels: {
        ...defaultXAxisOptions.labels,
        ...xAxis.labels
      }
    },
    yAxis: {
      title: {
        text: ''
      },
      gridLineWidth: 1, 
      gridLineColor: '#e0e0e0',
      gridLineDashStyle: 'Dash',
      tickInterval: 50,
      ...yAxis
    },
    plotOptions: {
      ...defaultPlotOptions,
      ...plotOptions,
      series: {
        ...defaultPlotOptions.series,
        ...plotOptions.series
      }
    },
    series: series.map(serie => ({
      ...serie,
      fillColor: gradient,
      fillOpacity: 0.1
    }))
  };

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        sx={{
          width: '90%'
        }}
      />
    </Box>
  );
};

export default LineChart
