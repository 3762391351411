import React from 'react'
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  SvgIcon
} from '@mui/material'

import { items } from '@/config/drawerItemsConfig'

function DrawerItem({ open }) {
  const { pathname } = useLocation()
  const { caseId, weekParam } = useParams();
  const navigate = useNavigate()

  return (
    <List>
      {items.map((item) => {
        const routeMatch = matchPath(`/dashboard/${item.linkTo}`, pathname)
        return (
          <ListItem
            key={item.id}
            disablePadding
            sx={{
              display: 'flex',
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 54,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
                pr: 0,
                backgroundColor: routeMatch || pathname === `/dashboard/${item.linkTo}/caseId/${caseId}` || pathname === `/dashboard/${item.linkTo}/${weekParam}` ? '#FDEDF1' : '#fff',
              }}
              onClick={() => {
                navigate(item.linkTo)
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : '16px',
                  justifyContent: 'center',
                }}
              >
                {/* <img
                  src={item.src}
                  alt={item.src}
                  style={{
                    height: '32px',
                    width: '32px',
                    objectFit: 'fill',
                  }}
                /> */}
                <SvgIcon 
                  component={item.src} 
                  inheritViewBox 
                  sx={{ 
                    fill: routeMatch || pathname === `/dashboard/${item.linkTo}/caseId/${caseId}` || pathname === `/dashboard/${item.linkTo}/${weekParam}` ? '#E95076' : '#737373' 
                  }} 
                />
              </ListItemIcon>
              {open && (
                <ListItemText
                  // sx={{
                  //   color: routeMatch
                  //     ? (theme) => theme.palette.neutral.dark
                  //     : 'black',
                  // }}
                  // primary={item.text}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      fontWeight: routeMatch || pathname === `/dashboard/${item.linkTo}/caseId/${caseId}` || pathname === `/dashboard/${item.linkTo}/${weekParam}`
                      ? 700 : 400,
                      color: routeMatch || pathname === `/dashboard/${item.linkTo}/caseId/${caseId}` || pathname === `/dashboard/${item.linkTo}/${weekParam}`
                        ? '#E95076'
                        : '#0A0A0A',
                    }}
                  >
                    {item.text}
                  </Typography>
                </ListItemText>
              )}
              {/* {routeMatch && (
                <Box
                  sx={{
                    position: 'absolute',
                    right: '0%',
                    width: '5px',
                    height: '32px',
                    borderRadius: '4px 0px 0px 4px',
                    backgroundColor: (theme) => theme.palette.primary.main,
                  }}
                />
              )} */}
            </ListItemButton>
          </ListItem>
        )
      })}
    </List>
  )
}

export default DrawerItem
