import axiosInstance from '@/api/axiosConfig';

// 登入
export const login = async (account, password) => {
  const response = await axiosInstance.post('/api/auth/token/', { 
    "account": account,
    "password": password
  });
  const { access } = response.data.data;
  localStorage.setItem('accessToken', access);
  return response.data;
};

// 忘記密碼
export const forgotPassword = async (email) => {
  const response = await axiosInstance.post('/api/auth/user/password/forgot/', { 
    "email": email
  });
  return response.data;
};

// 重設密碼
export const resetPassword = async (token, password) => {
  const response = await axiosInstance.post('/api/auth/user/password/reset/', { 
    "token": token,
    "password": password
  });
  return response.data;
};

