import axiosInstance from '@/api/axiosConfig';

// 取得週報資料(當期)
export const getReportData = async (currentMinDate, currentMaxDate) => {
  try {
    if (currentMinDate && currentMaxDate) {
      const response = await axiosInstance.post('/api/data/report/', {
        "start_time": currentMinDate,
        "end_time": currentMaxDate,
      });

      return response
    }
  } catch (error) {
    console.log(error)
  }
}

// 取得週報資料(前期)
export const getPreviousReportData = async (previousMinDate, previousMaxDate) => {
  try {
    if (previousMinDate && previousMaxDate) {
      const response = await axiosInstance.post('/api/data/report/', {
        "start_time": previousMinDate,
        "end_time": previousMaxDate,
      });

      return response
    }
  } catch (error) {
    console.log(error)
  }
}

// 取得週報 Excel
export const getReportExcelData = async (currentMinDate, currentMaxDate) => {
  try {
    if (currentMinDate && currentMaxDate) {
      const response = await axiosInstance.post('/api/data/report/export/', {
        "start_time": currentMinDate,
        "end_time": currentMaxDate,
      }, {
        responseType: 'blob',
      });

      return response.data;
    }
  } catch (error) {
    console.log(error)
  }
}

// 取得案件分析和排行案件 Excel
export const getReportCasesExcelData = async ( formattedMinDate, formattedMaxDate, ownerUnitSelected, subCategoriesSelected, searchSelected ) => {
  try {
    const response = await axiosInstance.post('/api/data/trend_analysis/export/', {
      "start_time": formattedMinDate,
      "end_time": formattedMaxDate,
      "category": subCategoriesSelected || {},
      "owner_unit": ownerUnitSelected || {},
      "search": searchSelected || ''
    }, {
      responseType: 'blob',
    });

    return response.data;
  } catch (error) {
    console.log(error)
  }
}

// 取得智慧摘要 Excel
export const getReportSummaryExcelData = async (year, weekNum, search = '') => {
  try {
    const response = await axiosInstance.post('/api/data/gpt/summary/detail/export/', {
      "year": year,
      "week_in_year":[weekNum],
      "search": search,
      "export_format": 'excel'
    }, {
      responseType: 'blob',
    });

    return response.data;
  } catch (error) {
    console.log(error)
  }
}

// 取得智慧摘要 CSV
export const getReportSummaryCsvData = async (year, weekNum, search = '') => {
  try {
    const response = await axiosInstance.post('/api/data/gpt/summary/detail/export/', {
      "year": year,
      "week_in_year":[weekNum],
      "search": search,
      "export_format": 'csv'
    }, {
      responseType: 'blob',
    });

    return response.data;
  } catch (error) {
    console.log(error)
  }
}
