import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import styled from '@emotion/styled';
import { 
  Stack, 
  Paper, 
  Box,
  Typography
} from '@mui/material'
import UseCaseDataStore from "@/hooks/UseCaseDataStore";
import UseDateRangeStore from "@/hooks/UseDateRangeStore";
import UseTableDataStore from '@/hooks/UseTableDataStore';
import { getCaseDetail } from '@/api/getCasesAPI'
import { ReactComponent as BackLeftArrowIcon } from '@/assets/backLeftArrow.svg'
import { ReactComponent as BackRightArrowIcon } from '@/assets/backRightArrow.svg'

const CustomBox = styled(Box)(({theme}) => ({
  background: theme.palette.bg.light,
  padding: '20px',
  borderRadius: '8px',
  '.title': {
    color: '#52697A',
    fontWeight: 'bold',
    marginBottom: '4px'
  },
  '.text': {
    color: theme.palette.neutral.dark,
  },
  '.content': {
    color: theme.palette.neutral.dark
  }
}))

function CaseDetail() {
  const [ caseDetail, setCaseDetail ] = useState({})
  const [ caseDetailFromApi, setCaseDetailFromApi ] = useState({})
  const [ cachedCaseDetails, setCachedCaseDetails ] = useState({});
  const [ caseIndex, setCaseIndex ] = useState(0)
  const [ casePrevIndex, setCasePrevIndex ] = useState(0)
  const [ caseNextIndex, setCaseNextIndex ] = useState(0)
  
  const { caseId } = useParams()
  const { caseData } = UseCaseDataStore()
  const { formattedMinDate, formattedMaxDate } = UseDateRangeStore()
  const { tableData } = UseTableDataStore()
  const navigate = useNavigate();
  const caseIds = (tableData || caseData?.responseData)?.slice((tableData || caseData?.responseData)?.findIndex((item) => item.case_id === caseId), (tableData || caseData?.responseData)?.findIndex((item) => item.case_id === caseId) + 10).map(item => item.case_id)

  const { data: caseDetailData, isLoading: isLoadingCaseDetailData, isError: isErrorCaseDetailData, refetch: refetchCaseDetailData } = useQuery({
    queryFn: () => getCaseDetail(formattedMinDate, formattedMaxDate, caseIds),
    queryKey: ['caseDetailData', formattedMinDate, formattedMaxDate, caseIds],
    refetchOnWindowFocus: false,
    enabled: false,
    staleTime: 300000,
    cacheTime: 300000
  })

  useEffect(() => {
    if (tableData || (caseData && caseData.responseData)) {
      const detailData = (tableData || caseData.responseData).find((item) => item.case_id === caseId)
      const index = (tableData || caseData.responseData).findIndex((item) => item.case_id === caseId)
      const prevIndex = (tableData || caseData.responseData)[index - 1]
      const nextIndex = (tableData || caseData.responseData)[index + 1]
  
      if (detailData) {
        setCaseDetail(detailData)
        setCaseIndex(index)
        setCasePrevIndex(prevIndex)
        setCaseNextIndex(nextIndex)
        localStorage.setItem('caseIndex', index);
        localStorage.setItem('detailData', JSON.stringify(detailData));
      }
    }
  }, [caseId, caseData, tableData])

  useEffect(() => {
    if (caseId === 'undefined') {
      navigate('/dashboard/case-analysis');
    }
  }, [caseId])

  // 緩存後 10 筆資料
  useEffect(() => {
      if (caseId && caseIds.length !== 0 && (!cachedCaseDetails[caseId] || cachedCaseDetails[caseId].timestamp < Date.now() - 60000)) {
        // 若沒有在緩存資料中，則 refetch API 獲取新資料
        refetchCaseDetailData().then(newData => {
          const newCache = {};
          newData.data.data.data.forEach(item => {
            newCache[item.case_id] = { ...item, timestamp: Date.now() };
          });
          setCachedCaseDetails(prev => ({ ...prev, ...newCache }));
          localStorage.setItem('cachedCaseDetails', JSON.stringify({ ...cachedCaseDetails, ...newCache }));
        });
      } else if (cachedCaseDetails[caseId]) {
        setCaseDetailFromApi(cachedCaseDetails[caseId])
      }

  }, [caseId, cachedCaseDetails, refetchCaseDetailData])

  useEffect(() => {
    const storedCaseDetails = localStorage.getItem('cachedCaseDetails');
    const storedDetailData = localStorage.getItem('detailData');
    const storedCaseIndex = localStorage.getItem('caseIndex');
    
    if (storedCaseDetails) {
      setCachedCaseDetails(JSON.parse(storedCaseDetails));
    }
    if (storedDetailData) {
      setCaseDetail(JSON.parse(storedDetailData));
    }
    if (storedCaseIndex) {
      setCaseIndex(storedCaseIndex);
    }
  }, []);

  const { main_category, sub_category, owner_unit, petition_subject, record_time, reply_time, satisfaction} = caseDetail

  const { age, sex, case_id, feedback, petition_content, reply_content, reason } = caseDetailFromApi

  return (
    <Paper 
      sx={{
        m: 2,
        borderRadius: '16px',
        height: 'auto',
        boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.05)'
      }}
    >
      <Stack
        sx={{
          pt: '22px',
          pb: '22px',
          borderBottom: (theme) => `1px solid ${theme.palette.neutral.light}`
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h3"
            sx={{
              pl: '16px',
              mr: '16px',
              borderLeft: '4px solid #52697A',
              fontWeight: 400
            }}
          >
            案件分析
          </Typography>
        </Box>
      </Stack>
      <Stack
        sx={{
          backgroundColor: '#F0F3F5',
          p: '20px',
          position: 'relative'
        }}
      >
        <Link 
          to={`/dashboard/case-analysis`}
          style={{
            textDecoration: 'none',
            display: 'flex',
            flexFlow: 'row wrap',
            alignItems: 'center',
            position: 'absolute',
            top: 0,
            bottom: 0
          }}
        >
          <BackLeftArrowIcon fill="#0A0A0A" />
          <Typography
            variant="h5"
            sx={{
              fontWeight: 400,
              color: (theme) => theme.palette.neutral.silver,
              pl: '4px'
            }}
          >
            返回案件分析
          </Typography>
        </Link>
        <Typography
          variant="h4"
          sx={{
            fontWeight: 400,
            textAlign: 'center'
          }}
        >
          案件編號 
          <span style={{ fontWeight: 700, marginLeft: '8px' }}>{case_id}</span>
        </Typography>
      </Stack>
      <Stack
        sx={{
          p: '12px'
        }}
      >
        <Stack
          sx={{
            display: 'flex',
            flexFlow: 'row wrap',
            gap: '12px'
          }}
        >
          <CustomBox
            sx={{
              width: '373px',
            }}
          >
            {/* 次案類 */}
            <Typography
              variant="h4"
              className='text'
              sx={{
                fontWeight: 400
              }}
            >
              {sub_category}
            </Typography>

            {/* 主案類 */}
            <Typography
              variant="h5"
              sx={{
                color: (theme) => theme.palette.primary.main,
                fontWeight: 'bold',
                mt: '4px',
                mb: '20px'
              }}
            >
              {main_category}
            </Typography>

            <Box
              sx={{
                display: 'flex',
                flexFlow: 'row wrap',
                mb: '20px'
              }}
            >
              <Box
                sx={{
                  width: '120px',
                  mr: '20px'
                }}
              >
                <Typography
                  variant="h5"
                  className='title'
                >
                  建立時間
                </Typography>
                <Typography
                  variant="h4"
                  className='text'
                  sx={{
                    fontWeight: 400,
                    // letterSpacing: '.08rem'
                  }}
                >
                  {record_time?.split(' ')[0]}
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="h5"
                  className='title'
                >
                  受理時間
                </Typography>
                <Typography
                  variant="h4"
                  className='text'
                  sx={{
                    fontWeight: 400,
                    // letterSpacing: '.08rem'
                  }}
                >
                  {reply_time ? reply_time?.split(' ')[0] : '-'}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                mb: '20px'
              }}
            >
              <Typography
                variant="h5"
                className='title'
              >
                承辦機關單位
              </Typography>
              <Typography
                variant="h4"
                className='text'
                sx={{
                  fontWeight: 400
                }}
              >
                {owner_unit || '-'}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h5"
                className='title'
              >
                回報者資訊
              </Typography>
              <Typography
                variant="h4"
                className='text'
                sx={{
                  fontWeight: 400
                }}
              >
                <span style={{ marginRight: age ? '8px' : '0px' }}>{age}</span>
                <span>{sex || '-'}</span>
              </Typography>
            </Box>
          </CustomBox>
          <Stack
            sx={{
              display: 'flex',
              flexFlow: 'column wrap',
              width: 'calc(100% - 385px)'
            }}
          >
            <CustomBox
              sx={{
                mb: '12px',
              }}
            >
              <Typography
                variant="h5"
                className='title'
              >
                陳情主旨、內容
              </Typography>
              <Typography
                variant="h4"
                className='text'
                sx={{
                  mb: '4px',
                  fontWeight: 400
                }}
              >
                {petition_subject ? petition_subject : '-'}
              </Typography>
              <Typography
                variant="h5"
                className='content'
                sx={{ 
                  whiteSpace: 'pre-line',
                  fontWeight: 400
                }}
              >
                {petition_content ? petition_content : '-'}
              </Typography>
            </CustomBox>
            <CustomBox
              sx={{
                flex: 1
              }}
            >
              <Typography
                variant="h5"
                className='title'
                sx={{
                  mb: '4px'
                }}
              >
                回覆內容
              </Typography>
              <Typography
                variant="h5"
                className='content'
                sx={{ 
                  whiteSpace: 'pre-line',
                  fontWeight: 400
                }}
              >
                {reply_content ? reply_content : '-'}
              </Typography>
            </CustomBox>
          </Stack>
          <CustomBox
            sx={{
              width: '373px',
              backgroundColor: '#F5F5F5 !important'
            }}
          >
            <Box
              sx={{
                mb: '20px'
              }}
            >
              <Typography
                variant="h5"
                className='title'
              >
                滿意度
              </Typography>
              <Typography
                variant="h4"
                className='text'
                sx={{
                  fontWeight: 400
                }}
              >
                {satisfaction ? satisfaction : '-'}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h5"
                className='title'
              >
                不滿意原因
              </Typography>
              <Typography
                variant="h4"
                className='text'
                sx={{
                  fontWeight: 400
                }}
              >
                {reason ? (Array.isArray(reason) && reason.length === 0 ? '-' : reason) : '-'}
              </Typography>
            </Box>
          </CustomBox>
          <CustomBox
            sx={{
              width: 'calc(100% - 385px)',
              backgroundColor: '#F5F5F5 !important'
            }}
          >
            <Typography
              variant="h5"
              className='title'
            >
              意見回饋
            </Typography>
            <Typography
              variant="h5"
              className='content'
              sx={{
                fontWeight: 400
              }}
            >
              {feedback ? feedback : '-'}
            </Typography>
          </CustomBox>
        </Stack>
      </Stack>
      <Stack
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.neutral.light}`,
          p: '20px',
          textAlign: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: '32px',
            m: '4px'
          }}
        >
          <Link
            to={`/dashboard/case-analysis/caseId/${casePrevIndex ? casePrevIndex.case_id : undefined}`}
            style={{
              textDecoration: 'none',
              display: 'flex',
              flexFlow: 'row wrap',
              alignItems: 'center',
              pointerEvents: caseIndex === 0 ? 'none' : 'auto'
            }}
          >
            <BackLeftArrowIcon fill={ caseIndex === 0 ? '#D4D4D4' : '#0A0A0A'} />
            <Typography
              variant="h5"
              sx={{
                fontWeight: 400,
                color: (theme) => `${ caseIndex === 0 ? theme.palette.neutral.light : theme.palette.neutral.dark}`,
                pl: '4px'
              }}
            >
              上一則
            </Typography>
          </Link>
          <Link
            to={`/dashboard/case-analysis/caseId/${caseNextIndex ? caseNextIndex.case_id : undefined}`}
            style={{
              textDecoration: 'none',
              display: 'flex',
              flexFlow: 'row wrap',
              alignItems: 'center',
              pointerEvents: caseIndex === (tableData || caseData?.responseData)?.length - 1 ? 'none' : 'auto'
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: 400,
                color: (theme) => `${ caseIndex === (tableData || caseData?.responseData)?.length - 1 ? theme.palette.neutral.light : theme.palette.neutral.dark}`,
                pr: '4px'
              }}
            >
              下一則
            </Typography>
            <BackRightArrowIcon fill={ caseIndex === (tableData || caseData?.responseData)?.length - 1 ? '#D4D4D4' : '#0A0A0A'} />
          </Link>
        </Box>
      </Stack>
    </Paper>
  );
}

export default CaseDetail;