import { create } from 'zustand';
import { DateTime } from 'luxon'

// 預設日期範圍為近一周內
const UseDateRangeStore = create((set) => ({
  formattedMinDate: DateTime.now().startOf('day').plus({ days: -7 }).toFormat('yyyy/MM/dd'),
  formattedMaxDate: DateTime.now().endOf('day').plus({ days: -1 }).toFormat('yyyy/MM/dd'),
  setFormattedDates: (minDate, maxDate) => {
    set({ formattedMinDate: minDate, formattedMaxDate: maxDate });
  },
}));

export default UseDateRangeStore;