import React from 'react';
import { Box } from '@mui/material';

const RankBarChart = ({ value, type }) => {
  return (
    <Box
      sx={{
        height: type === 'times_subKey' ? '8px' : '12px',
        width: '100%',
        backgroundColor: theme => type === 'times_subKey' ? theme.palette.bg.light : theme.palette.bg.dark,
        borderRadius: '50px',
        position: 'relative',
        mr: type === 'times_subKey' ? '4px' : '0px',
        transition: 'width 0.3s ease-in-out',
        overflow: 'hidden'
      }}
    >
      <Box
        sx={{
          height: type === 'times_subKey' ? '8px' : '12px',
          width: `${value * 100}%`,
          backgroundColor: theme => type === 'times_subKey' || type === 'satisfaction_total' ? '#52697A' : theme.palette.info.main,
          borderRadius: '50px 0 0 50px',
          marginRight: '10px',
          transition: 'width 0.3s ease-in-out',
        }}
      />
    </Box>
  );
};

export default RankBarChart;