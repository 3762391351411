import React from 'react';
import { Box, Typography } from '@mui/material';

const CustomBarChart = ({ data }) => {
  return (
    <Box 
      sx={{ 
        width: '100%', 
        margin: '12px 0' 
      }}
    >
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          gap: '4px',
        }}
      >
        {data.map((item, index) => (
          <Box 
            key={index} 
            sx={{ 
              display: 'flex',
              flexFlow: 'row wrap',
              alignItems: 'center',
              p: '3px 0'
            }}
          >
            <Box sx={{ width: '50%', mr: '12px' }}>
              <Typography 
                variant="body1" 
                sx={{ 
                  fontSize: '14px', 
                  lineHeight: '21px',
                  fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
              >
                {item.key}
              </Typography>
            </Box>
            <Box
              sx={{
                height: '15px',
                width: 'calc(50% - 70px)',
                backgroundColor: '#E5E5E5',
                borderRadius: '50px',
                position: 'relative',
                marginRight: '10px',
                transition: 'width 0.3s ease-in-out',
              }}
            >
              <Box
                sx={{
                  height: '15px',
                  width: `${item.percent * 100}%`,
                  backgroundColor: '#E95076',
                  borderRadius: '50px 0 0 50px',
                  marginRight: '10px',
                  transition: 'width 0.3s ease-in-out',
                }}
              />
              <Typography
                sx={{
                  color: '#404040',
                  fontSize: '10px',
                  lineHeight: '15px',
                  fontWeight: 'bold',
                  position: 'absolute',
                  right: '4px',
                  top: '0px',
                  bottom: '0px',
                  margin: 'auto'
                }}
              >
                {Math.round(item.percent * 100)}%
              </Typography>
            </Box>
            <Typography 
              sx={{ 
                fontSize: '10px',
                lineHeight: '15px',
                fontWeight: 'bold',
                letterSpacing: '.05rem'
              }}
            >
              {item.doc_count.toLocaleString()}件
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default CustomBarChart;