import React from 'react'
import styled from '@emotion/styled'
import {
  Drawer as MuiDrawer, Box, Button
} from '@mui/material'
import { useNavigate } from 'react-router-dom'

const openedMixin = (theme, width) => ({
  width,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme, width) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: width - 12,
  [theme.breakpoints.up('sm')]: {
    width,
  },
})

const CustomDrawer = styled(MuiDrawer)(
  ({ theme, open, width }) => ({
    width,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme, width),
      '& .MuiDrawer-paper': {
        ...openedMixin(theme, width),
        borderRight: '0px',
        backgroundColor: 'white',
      },
    }),
    ...(!open && {
      ...closedMixin(theme, width),
      '& .MuiDrawer-paper': {
        ...closedMixin(theme, width),
        borderRight: '0px',
        backgroundColor: 'white',
      },
    }),
  }),
)

const StyledButton = styled(Button)(({ open }) => ({
  width: '158px',
  position: 'absolute',
  bottom: '44px',
  left: 0,
  right: 0,
  margin: 'auto',
  zIndex: 1200,
  transform: open ? 'translateX(0)' : 'translateX(-100%)'
}));

function Drawer({ width, closedWidth, open, children }) {
  const navigate = useNavigate()

  const handleLogout = () => {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('formattedMinDate');
    localStorage.removeItem('formattedMaxDate');
    localStorage.removeItem('caseIndex');
    localStorage.removeItem('detailData');
    navigate('/')
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <CustomDrawer 
        variant="permanent" 
        width={open ? width : closedWidth} 
        open={open}
        sx={{
          '& .MuiPaper-root': {
            mt: '100px'
          }
        }}
      >
        {children}
      </CustomDrawer>
      <StyledButton
        open={open}
        onClick={handleLogout}
      >
        登出
      </StyledButton>
    </Box>
  )
}

export default Drawer
