import React, { useState, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import {
  Box,
  useTheme,
} from '@mui/material'
import Header from '@/components/Header'
import Drawer from '@/components/Drawer'
import DrawerItem from '@/components/DrawerItem'
import UseAxiosInterceptors from '@/hooks/UseAxiosInterceptors'

const drawerWidth = 224
const drawerClosedWidth = 56
const topBarHeight = 72

function DashboardLayout() {
  const [open, setOpen] = useState(true)
  const theme = useTheme()
  const navigate = useNavigate()
  UseAxiosInterceptors()
  
  const handleDrawerOnChange = () => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, theme.transitions.duration.enteringScreen)
    setOpen((prevState) => !prevState)
  }

  useEffect(() => {
    const token = localStorage.getItem('accessToken')
    if (!token) {
      navigate('/')
    }
  }, [])
      
  return (
    <>
      <Header 
        width={drawerWidth}
        closedWidth={drawerClosedWidth}
        height={topBarHeight}
        open={open}
        drawerOnTrigger={handleDrawerOnChange}
      />
      <Box sx={{ display: 'flex' }}>
        <Drawer
          width={drawerWidth}
          closedWidth={drawerClosedWidth}
          height={topBarHeight}
          open={open}
          drawerOnTrigger={handleDrawerOnChange}
        >
          <DrawerItem
            open={open}
          />
        </Drawer>
        <Box
          sx={{
            height: `calc(100vh - ${topBarHeight}px)`,
            overflow: 'auto',
            backgroundColor: (theme) => theme.palette.bg.dark,
            flexGrow: 1,
            marginTop: `${topBarHeight}px`,
            padding: 0,
            position: 'relative',
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </>
  )
}

export default DashboardLayout