import { styled } from "@mui/material/styles";
import { Alert } from "@mui/material"

const StyledAlert = styled(Alert)({
	position: "absolute",
	top: "10px",
	left: "0",
	right: "0",
	width: "300px",
	margin: "auto",
});

export default StyledAlert

