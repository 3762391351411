/* eslint-disable react/no-this-in-sfc */
/* eslint-disable max-len */
import { useCallback, useEffect, forwardRef } from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useTheme } from "@mui/material/styles";
import wordCloud from "highcharts/modules/wordcloud";
import watermarkLogo from "@/utils/watermarkLogo";

const archimedeanSpiral = function archimedeanSpiral(t) {
	return {
		x: t * Math.cos(t) * 0.2,
		y: t * Math.sin(t) * 0.1,
	};
};

if (typeof Highcharts === "object") {
	wordCloud(Highcharts);

	Highcharts.seriesTypes.wordcloud.prototype.spirals.archimedean =
		archimedeanSpiral;
}

const WordCloudChart = (
	{
		title = "",
		data,
		maxFontSize = 100,
		chartOptionOverrides = {},
		onWordCloudClick,
	},
	ref
) => {
	const theme = useTheme();
	const { colors, default: defaultColors } =
		theme.palette.dashboard.wordCloud;

	const calculateFontSize = useCallback(
		(relativeWeight) => {
			const fontSize = Math.floor(maxFontSize * relativeWeight);
			return fontSize <= 16 ? 16 : fontSize;
		},
		[maxFontSize]
	);

	const options = {
		series: [
			{
				type: "wordcloud",
				data: data.map((d, ind) => ({
					color: colors[ind] || defaultColors,
					...d,
				})),
				rotation: {
					from: 0,
					to: 0,
				},
				// FIXME: directly set a custom font will cause rendering issue.
				// Since the first render is earlier than the custom font is loaded, the chart will use the space taken by default font to calculate.
				// And in the subsequent re-render, the chart rendering with different font will change its layout a lot
				// style: { fontFamily: 'Roboto, "Noto Sans TC"' },
				spiral: "archimedean",
			},
		],
		plotOptions: {
			wordcloud: {
				events: {
					click: onWordCloudClick,
				},
				cursor: onWordCloudClick ? "pointer" : "default",
			},
		},
		tooltip: {
			enabled: false,
		},
		title: {
			text: title,
		},
		credits: {
			enabled: false,
		},
		chart: {
			backgroundColor: "transparent",
		},
		accessibility: {
			enabled: false,
		},
		exporting: {
			enabled: false,
			scale: 3,
			sourceWidth: 1740,
			sourceHeight: 1000,
			chartOptions: {
				chart: {
					margin: 150,
					marginBottom: 200,
					backgroundColor: "white",
					events: {
						load() {
							this.renderer
								.image(watermarkLogo, 30, 930, 143, 31)
								.add();
						},
					},
				},
			},
		},
		...chartOptionOverrides,
	};

	useEffect(() => {
		Highcharts.seriesTypes.wordcloud.prototype.deriveFontSize =
			calculateFontSize;
	}, [calculateFontSize]);

	return (
		<HighchartsReact highcharts={Highcharts} options={options} ref={ref} />
	);
};

export default forwardRef(WordCloudChart);
