import axiosInstance from '@/api/axiosConfig';

export const getCategoryCaseCount = async (formattedMinDate, formattedMaxDate) => {
  try {
    if (formattedMinDate !== '' || formattedMaxDate !== '') {
      const response = await axiosInstance.post('/api/data/rank_stats/category/case_count/', {
        "start_time": formattedMinDate,
        "end_time": formattedMaxDate,
      });
      
      return response
    }
  } catch (error) {
    console.log(error)
  }
}

export const getOwnerUnitCaseCount = async (formattedMinDate, formattedMaxDate) => {
  try {
    if (formattedMinDate !== '' || formattedMaxDate !== '') {
      const response = await axiosInstance.post('/api/data/rank_stats/owner_unit/case_count/', {
        "start_time": formattedMinDate,
        "end_time": formattedMaxDate,
      });
      
      return response
    }
  } catch (error) {
    console.log(error)
  }
}

export const getCategorySatisfaction = async (formattedMinDate, formattedMaxDate) => {
  try {
    if (formattedMinDate !== '' || formattedMaxDate !== '') {
      const response = await axiosInstance.post('/api/data/rank_stats/category/satisfaction/', {
        "start_time": formattedMinDate,
        "end_time": formattedMaxDate,
      });
      
      return response
    }
  } catch (error) {
    console.log(error)
  }
}

export const getOwnerUnitSatisfaction = async (formattedMinDate, formattedMaxDate) => {
  try {
    if (formattedMinDate !== '' || formattedMaxDate !== '') {
      const response = await axiosInstance.post('/api/data/rank_stats/owner_unit/satisfaction/', {
        "start_time": formattedMinDate,
        "end_time": formattedMaxDate,
      });
      
      return response
    }
  } catch (error) {
    console.log(error)
  }
}

