import axiosInstance from '@/api/axiosConfig';

export const getWordCloud = async (formattedMinDate, formattedMaxDate, ownerUnitSelected, subCategoriesSelected, searchSelected, satisfaction) => {
  try {
    const response = await axiosInstance.post('/api/data/word_cloud/', {
      "start_time": formattedMinDate,
      "end_time": formattedMaxDate,
      "category": subCategoriesSelected || {},
      "owner_unit": ownerUnitSelected || {},
      "search": searchSelected || '',
      "satisfaction": satisfaction || []
    });
    
    return response
  } catch (error) {
    // console.log(error)
    if (error?.response?.data && error.response?.status === 400) {
      throw new Error(error.response.data.message);
    }
  }
}

export const getPreviousWordCloud = async (previousFormattedMinDate, previousFormattedMaxDate, ownerUnitSelected, subCategoriesSelected, searchSelected, satisfaction) => {
  try {
    const response = await axiosInstance.post('/api/data/word_cloud/', {
      "start_time": previousFormattedMinDate,
      "end_time": previousFormattedMaxDate,
      "category": subCategoriesSelected || {},
      "owner_unit": ownerUnitSelected || {},
      "search": searchSelected || '',
      "satisfaction": satisfaction || []
    });
    
    return response
  } catch (error) {
    // console.log(error)
    if (error?.response?.data && error.response?.status === 400) {
      throw new Error(error.response.data.message);
    }
  }
}

