import { create } from 'zustand';

const UseSubCategoryStore = create((set) => ({
  subCategoriesSelected: {},
  // 更新選取主案類狀態
  updateMainCategorySelected: (isChecked, category, caseData) => set((state) => {
    if (isChecked) {
      return {
        subCategoriesSelected: {
          ...state.subCategoriesSelected,
          [category]: caseData?.categories[category]
        }
      }
    } else {
      const { [category]: _, ...rest } = state.subCategoriesSelected
      return {
        subCategoriesSelected: rest
      }
    }
  }),
  // 更新選取次案類狀態
  updateSubCategorySelected: (category, subCategory, isChecked) => set((state) => {
    const prevSelected = state.subCategoriesSelected[category] || []

    const updatedSelection = isChecked
    ? [...prevSelected, subCategory]
    : prevSelected.filter((item) => item !== subCategory);

    // 若取消選擇次案類的唯一項目時，則移除相應的主案類
    if (!updatedSelection.length) {
      const { [category]: _, ...rest } = state.subCategoriesSelected;
      return {
        subCategoriesSelected: rest
      }
    }

    return {
      subCategoriesSelected: {
        ...state.subCategoriesSelected,
        [category]: updatedSelection
      }
    }
  }),
  // 取消選取案件
  deleteSubCategorySelected: (chipToDelete) => set((state) => {
    const updated = { ...state.subCategoriesSelected };
    Object.keys(updated).forEach((key) => {
      updated[key] = updated[key].filter((sub) => sub !== chipToDelete.replace(`${key}/`, ''));
      if (updated[key].length === 0) {
        delete updated[key];
      }
    });
    return {
      subCategoriesSelected: updated
    }
  }),
  // 清空已選取的案件
  clearSubCategorySelected: () => { 
    set({ subCategoriesSelected: {} })
  }
}));

export default UseSubCategoryStore;