import { DateTime } from 'luxon'
import { addDays, format } from 'date-fns';

const dateRangePickerRanges = [
  // {
  //   label: '三天內',
  //   range: () => ({
  //     startDate: DateTime.now().startOf('day').plus({ days: -3 }).toJSDate(),
  //     endDate: DateTime.now().endOf('day').plus({ days: -1 }).toJSDate(),
  //   }),
  // },
  {
    label: '近一週',
    range: () => ({
      startDate: DateTime.now().startOf('day').plus({ days: -7 }).toJSDate(),
      endDate: DateTime.now().endOf('day').plus({ days: -1 }).toJSDate(),
    }),
  },
  {
    label: '一個月內',
    range: () => ({
      startDate: DateTime.now().startOf('day').plus({ days: -30 }).toJSDate(),
      endDate: DateTime.now().endOf('day').plus({ days: -1 }).toJSDate(),
    }),
  },
  {
    label: '三個月內',
    range: () => ({
      startDate: DateTime.now().startOf('day').plus({ days: -90 }).toJSDate(),
      endDate: DateTime.now().endOf('day').plus({ days: -1 }).toJSDate(),
    }),
  },
  {
    label: '自訂時間區間',
    range: () => ({
      startDate: addDays(new Date(), -1),
      endDate: addDays(new Date(), -1),
    }),
    isSelected: range => {
      const start = format(range.startDate, 'yyyy/MM/dd');
      const end = format(range.endDate, 'yyyy/MM/dd');
      const oneWeekStart = DateTime.now().startOf('day').plus({ days: -7 }).toFormat('yyyy/MM/dd');
      const oneMonthStart = DateTime.now().startOf('day').plus({ days: -30 }).toFormat('yyyy/MM/dd');
      const threeMonthStart = DateTime.now().startOf('day').plus({ days: -90 }).toFormat('yyyy/MM/dd');
      const periodEnd = DateTime.now().endOf('day').plus({ days: -1 }).toFormat('yyyy/MM/dd');
      
      return !(
        (start === oneWeekStart && end === periodEnd) ||
        (start === oneMonthStart && end === periodEnd) ||
        (start === threeMonthStart && end === periodEnd)
      );
    },
  }
]

export default dateRangePickerRanges