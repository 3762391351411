const previousColors = [
  "#4394cf",
  "#3681b8",
  "#72b9ed",
  "#2475b1",
  "#93b4cd",
  "#536571",
  "#627785",
  "#738c9d",
  "#89a0b1",
  "#b5c1cc",
]

const currentColors = [
  "#c33655",
  "#ce446d",
  "#cb655f",
  "#a62919",
  "#dd9c62",
  "#be89d9",
  "#627785",
  "#738c9d",
  "#89a0b1",
  "#b5c1cc",
]

export { previousColors, currentColors }