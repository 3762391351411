import { ReactComponent as HomeIcon } from '@/assets/home.svg'
import { ReactComponent as AnalysisIcon } from '@/assets/analysis.svg'
import { ReactComponent as RankIcon } from '@/assets/rank.svg'
import { ReactComponent as SmartIcon } from '@/assets/smart.svg'

export const items = [
  {
    id: 0,
    text: '首頁',
    src: HomeIcon,
    linkTo: '',
  },
  {
    id: 1,
    text: '案件分析',
    src: AnalysisIcon,
    linkTo: 'case-analysis',
  },
  {
    id: 2,
    text: '排行統計分析',
    src: RankIcon,
    linkTo: 'rank-statistics',
  },
  {
    id: 3,
    text: '智慧摘要分析',
    src: SmartIcon,
    linkTo: 'smart-summary',
  }
]
