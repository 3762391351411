import React, { useState, useEffect, useRef } from 'react';
import { Button, Box, Typography, Stack, Menu, MenuItem, CircularProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query'
import { jsPDF } from 'jspdf';
import { DateTime } from 'luxon'
import Highcharts from 'highcharts'
import html2canvas from 'html2canvas';
import { transformChartDate } from '@/utils/transformChartDate'
import LineChart from '@/components/LineChart';
import WordCloudChart from '@/components/WordCloudChart';
import { getReportData, getPreviousReportData, getReportExcelData } from '@/api/getReportDataAPI'
import { getHomeData } from '@/api/getHomeDataAPI'
import { ReactComponent as ReportLogoIcon } from '@/assets/header-logo.svg'
import { ReactComponent as DownloadIcon } from '@/assets/download.svg'
import { ReactComponent as ExcelExportIcon } from '@/assets/excelExport.svg'
import { ReactComponent as PdfExportIcon } from '@/assets/pdfExport.svg'

const ExportWeekReport = () => {
  const [report, setReport] = useState({});
  const [homeData, setHomeData] = useState({});
  const [previousReport, setPreviousReport] = useState({});
  const [isReportRendered, setIsReportRendered] = useState(false);
  const [isPreviousReportRendered, setIsPreviousReportRendered] = useState(false);
  const [isHomeReportRendered, setIsHomeReportRendered] = useState(false);
  const [reportMenuAnchorEl, setReportMenuAnchorEl] = useState(null);
  const [isReportExcelLoading, setIsReportExcelLoading] = useState(false);
  const [isReportPdfLoading, setIsReportPdfLoading] = useState(false);
  const contentRef = useRef();
  const reportMenuOpen = Boolean(reportMenuAnchorEl);

  const today = DateTime.local();
  const dayOfWeek = today.weekday;

  // 根據今天禮拜幾來取得上週一及日的日期
  const lastMonday = today.minus({ days: dayOfWeek + 6 }).startOf('day');
  const lastSunday = lastMonday.plus({ days: 6 })
  const currentMinDate = lastMonday.toFormat('yyyy/MM/dd')
  const currentMaxDate = lastSunday.toFormat('yyyy/MM/dd')
  const previousMinDate = lastMonday.minus({ days: 7 }).toFormat('yyyy/MM/dd')
  const previousMaxDate = lastSunday.minus({ days: 7 }).toFormat('yyyy/MM/dd')

  const { data: reportData, refetch: refetchReportData } = useQuery({
    queryFn: () => getReportData(currentMinDate, currentMaxDate),
    enabled: false,
    queryKey: ['reportData'],
    refetchOnWindowFocus: false,
  })

  const { data: previousReportData, refetch: refetchPreviousReportData } = useQuery({
    queryFn: () => getPreviousReportData(previousMinDate, previousMaxDate),
    enabled: false,
    queryKey: ['previousReportData'],
    refetchOnWindowFocus: false,
  })

  const { data: homePageData, isLoading: isLoadingHomeData, isError: isErrorHomeData, refetch: refetchHomeData } = useQuery({
    queryFn: () => getHomeData(currentMinDate, currentMaxDate),
    queryKey: ['homeData'],
    refetchOnWindowFocus: false,
    staleTime: 60000
  })

  const { data: reportExcelData, refetch: refetchReportExcelData } = useQuery({
    queryFn: () => getReportExcelData(currentMinDate, currentMaxDate),
    enabled: false,
    queryKey: ['reportExcelData'],
    refetchOnWindowFocus: false,
  })

  // 週報 PDF 下載
  const handleDownloadPdf = async () => {
    const content = contentRef.current;
    const pages = Array.from(content.querySelectorAll('.page'));

    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
    });

    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];
      const canvas = await html2canvas(page, { scale: 2 });
      const imgData = canvas.toDataURL('image/png');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      if (i > 0) {
        pdf.addPage();
      }
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    }

    pdf.save(`report-${currentMinDate}-${currentMaxDate}.pdf`);
    setIsReportPdfLoading(false)
  };

  const handleDownloadXls = () => {
    refetchReportExcelData()
  };

  const showReportMenu = (event) => {
    setReportMenuAnchorEl(event.currentTarget);
  };

  const handleReportMenuClose = () => {
    setReportMenuAnchorEl(null);
  };

  // 週報 Excel 下載
  useEffect(() => {
    if (reportExcelData) {
      try {
        const url = window.URL.createObjectURL(reportExcelData);
        const a = document.createElement('a');
        a.href = url;
        a.download = `report-${currentMinDate}-${currentMaxDate}.xlsx`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        setIsReportExcelLoading(false)
      } catch (err) {
        console.error(err);
      }
    }
  }, [reportExcelData]);

  useEffect(() => {
    if (reportData && reportData.data.data) {
      setReport(reportData.data.data)
    }

    if (previousReportData && previousReportData.data.data) {
      setPreviousReport(previousReportData.data.data)
    }

    if (homePageData && homePageData.data.data) {
      setHomeData(homePageData.data.data)
    }
  }, [reportData, previousReportData, homePageData])

  useEffect(() => {
    if (Object.values(report).length !== 0) {
      setIsReportRendered(true);
    }

    if (Object.values(previousReport).length !== 0) {
      setIsPreviousReportRendered(true);
    }

    if (Object.values(homeData).length !== 0) {
      setIsHomeReportRendered(true);
    }
  }, [report, previousReport, homeData])

  useEffect(() => {
    if (isReportRendered && isPreviousReportRendered && isHomeReportRendered) {
      // 延遲三秒為了確保文字雲的動態效果已展開完成
      setTimeout(() => handleDownloadPdf(), 3000)
    }
  }, [isReportRendered, isPreviousReportRendered, isHomeReportRendered]);

  return (
    <Stack>
      <Button
        sx={{
          width: '120px',
          height: '37px',
          mr: '20px',
          p: 0
        }}
        onClick={showReportMenu}
      >
        <DownloadIcon fill="#ffffff" style={{ marginRight: '4px' }}/>
        下載週報
      </Button>
      <Menu
        anchorEl={reportMenuAnchorEl}
        open={reportMenuOpen}
        onClose={handleReportMenuClose}
        sx={{
          '& .MuiPaper-root': {
            marginTop: '5px',
            backgroundColor: 'transparent',
            p: 0,
            borderRadius: '99px',
            height: 'auto',
            '& .MuiList-root': {
              padding: 0,
              display: 'flex',
              li: {
                p: 0,
                '&:hover': {
                  backgroundColor: 'transparent',
                }
              }
            },
          }
        }}
      >
        {['EXCEL', 'PDF'].map((option) => (
          <MenuItem 
            key={option}
          >
            <Button 
              sx={{
                backgroundColor: 'white',
                color: '#0A0A0A',
                fontWeight: 'normal',
                p: '8px 20px',
                width: '100%',
                borderRadius: option === 'EXCEL' ? '99px 0 0 99px' : '0 99px 99px 0',
                border: '1px solid #ACBCC8',
                '&:hover': {
                  backgroundColor: '#CFD8DF',
                },
                '&:focus': {
                  backgroundColor: '#CFD8DF',
                },
              }}
              disabled={isReportExcelLoading || isReportPdfLoading}
              onClick={() => {
                if (option === 'PDF') {
                  setIsReportPdfLoading(true)
                  if (Object.values(report).length === 0) {
                    refetchReportData();
                  } 
                  
                  if (Object.values(previousReport).length === 0) {
                    refetchPreviousReportData();
                  } 
                  
                  if (Object.values(report).length !== 0 && Object.values(previousReport).length !== 0) {
                    handleDownloadPdf()
                  }
                } else {
                  setIsReportExcelLoading(true)
                  handleDownloadXls()
                }
              }}
            >
              {option === 'EXCEL' ? 
                (isReportExcelLoading ? <CircularProgress size={20} /> : <ExcelExportIcon />) 
                : 
                (isReportPdfLoading ? <CircularProgress size={20} value={80} /> : <PdfExportIcon />)
              }
              <Typography
                variant='h5'
                sx={{
                  ml: '4px',
                  fontWeight: 400
                }}
              >
                {option}
              </Typography>
            </Button>
          </MenuItem>
        ))}
      </Menu>
      <Box 
        ref={contentRef}
      >
        {/* 第一頁 */}
        <Box 
          className="page page1" 
          sx={{ 
            padding: '9.5mm 10mm', 
            backgroundColor: '#ffffff',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #ACBCC8',
              pb: '3mm',
              mb: '7mm'
            }}
          >
            <ReportLogoIcon 
              style={{
                width: '35mm',
                mr: '3mm',
              }}
            />
            <Typography
              sx={{
                backgroundColor: '#F5F5F5',
                borderRadius: '99mm',
                p: '1mm 4mm',
                fontSize: '14px',
                letterSpacing: '.08rem'
              }}
            >
              {currentMinDate} ~ {currentMaxDate}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: '10px',
              position: 'absolute',
              right: '10mm',
              top: '12mm',
              color: '#737373',
              letterSpacing: '.08rem'
            }}
          >
            1/6
          </Typography>

          {/* 趨勢圖 */}
          <Box
            sx={{
              backgroundColor: '#FAFAFA',
              borderRadius: '8px',
              mb: '3.5mm'
            }}
          >
            <Typography
              sx={{
                pl: '4mm',
                mt: '4mm',
                mb: '3mm',
                fontSize: '14px',
                fontWeight: 'bold',
                letterSpacing: '.08rem',
                borderLeft: '1.5mm solid #E95076',
                color: '#404040',
                fontFamily: 'sans-serif'
              }}
            >
              {currentMinDate} ~ {currentMaxDate}
            </Typography>
            <LineChart
              categories={report && report.daily_count?.date_count && Object.keys(report.daily_count.date_count)?.map((date) => transformChartDate(date))}
              series={[
                {
                  name: '每日案件',
                  data: report && report.daily_count?.date_count && Object.values(report.daily_count.date_count),
                  color: '#E95076',
                  lineWidth: 1.5,
                },
              ]}
              xAxis={{
                labels: {
                  style: {
                    color: '#0A0A0A',
                    fontSize: '10px',
                    lineHeight: '15px'
                  },
                }
              }}
              yAxis={{
                min: report && report.daily_count?.date_count && Math.floor(Math.min(...Object.values(report?.daily_count?.date_count)) / 50) * 50,
                max: report && report.daily_count?.date_count && Math.ceil(Math.max(...Object.values(report?.daily_count?.date_count)) / 50) * 50
              }}
              plotOptions={{
                series: {
                  marker: {
                    enabled: true,
                    radius: 4,
                    fillColor: '#FFFFFF',
                    lineWidth: 2,
                    lineColor: null
                  }
                }
              }}
              gradient={{
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                stops: [
                  [0, Highcharts.color('#E95076').setOpacity(0.2).get('rgba')],
                  [1, Highcharts.color('#E95076').setOpacity(0).get('rgba')]
                ]
              }}
              chart={{
                backgroundColor: '#FAFAFA',
                height: '170',
                marginBottom: '40'
              }}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              gap: '12px',
              mb: '3.5mm'
            }}
          >
            {/* 機關排行 */}
            <Box
              sx={{
                backgroundColor: '#FAFAFA',
                borderRadius: '8px',
                width: '50%'
              }}
            >
              <Box
                sx={{
                  pl: '4mm',
                  mt: '4mm',
                  mb: '3mm',
                  borderLeft: '1.5mm solid #E95076',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    letterSpacing: '.05rem',
                    color: '#404040',
                    fontFamily: 'sans-serif'
                  }}
                >
                  機關排行
                </Typography>
                <Typography
                  sx={{
                    fontSize: '10px',
                    letterSpacing: '.03rem',
                    color: '#737373',
                  }}
                >
                  *第10名以後因數量較少，此處不列出。
                </Typography>
              </Box>
              {report && report.stats_detail && (
                <Box 
                  sx={{ 
                    width: '100%', 
                    margin: '12px 0',
                    p: '0 12px'
                  }}
                >
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      flexDirection: 'column', 
                      // gap: '4px',
                    }}
                  >
                    {report.stats_detail.owner_unit?.map((item, index) => (
                      <Box 
                        key={index} 
                        sx={{ 
                          display: 'flex',
                          flexFlow: 'row wrap',
                          alignItems: 'center',
                          p: '0'
                        }}
                      >
                        <Box sx={{ width: '100px', mr: '12px', overflow: 'hidden' }}>
                          <Typography 
                            sx={{ 
                              fontSize: '14px', 
                              lineHeight: '21px',
                              fontWeight: index <= 4 ? '800' : 'normal',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                          >
                            {item.key.length > 6 ? item.key.substring(0, 6) + '...' : item.key}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            height: '10px',
                            width: 'calc(100% - 160px)',
                            backgroundColor: '#E5E5E5',
                            borderRadius: '99px',
                            position: 'relative',
                            marginRight: '15px',
                            overflow: 'hidden'
                          }}
                        >
                          <Box
                            sx={{
                              height: '10px',
                              width: `${item.percent * 100}%`,
                              backgroundColor: '#E95076',
                              borderRadius: '99px 0 0 99px',
                            }}
                          />
                          <Typography
                            sx={{
                              color: '#404040',
                              fontSize: '9px',
                              lineHeight: '9px',
                              fontWeight: 'bold',
                              position: 'absolute',
                              right: '4px',
                              top: '0px',
                              bottom: '0px',
                              margin: 'auto'
                            }}
                          >
                            {Math.round(item.percent * 100)}%
                          </Typography>
                        </Box>
                        <Typography 
                          sx={{ 
                            fontSize: '10px',
                            lineHeight: '15px',
                            fontWeight: 'bold',
                            letterSpacing: '.05rem'
                          }}
                        >
                          {item.doc_count}件
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>

            {/* 分類排行 */}
            <Box
              sx={{
                backgroundColor: '#FAFAFA',
                borderRadius: '8px',
                width: '50%'
              }}
            >
              <Box
                sx={{
                  pl: '4mm',
                  mt: '4mm',
                  mb: '3mm',
                  borderLeft: '1.5mm solid #E95076',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 'bold',
                    letterSpacing: '.05rem',
                    color: '#404040',
                    fontFamily: 'sans-serif'
                  }}
                >
                  分類排行
                </Typography>
                <Typography
                  sx={{
                    fontSize: '10px',
                    letterSpacing: '.03rem',
                    color: '#737373',
                  }}
                >
                  *第10名以後因數量較少，此處不列出。
                </Typography>
              </Box>
              {homeData && homeData.category_count && (
                <Box 
                  sx={{ 
                    width: '100%', 
                    margin: '12px 0',
                    p: '0 12px'
                  }}
                >
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      flexDirection: 'column', 
                      // gap: '4px',
                    }}
                  >
                    {homeData.category_count?.map((item, index) => (
                      <Box 
                        key={index} 
                        sx={{ 
                          display: 'flex',
                          flexFlow: 'row wrap',
                          alignItems: 'center',
                          p: '0'
                        }}
                      >
                        <Box sx={{ width: '100px', mr: '12px', overflow: 'hidden' }}>
                          <Typography 
                            sx={{ 
                              fontSize: '14px', 
                              lineHeight: '21px',
                              fontWeight: index <= 4 ? '800' : 'normal',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                          >
                            {item.key.length > 6 ? item.key.substring(0, 6) + '...' : item.key}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            height: '10px',
                            width: 'calc(100% - 160px)',
                            backgroundColor: '#E5E5E5',
                            borderRadius: '99px',
                            position: 'relative',
                            marginRight: '15px',
                            overflow: 'hidden'
                          }}
                        >
                          <Box
                            sx={{
                              height: '10px',
                              width: `${item.percent * 100}%`,
                              backgroundColor: '#E95076',
                              borderRadius: '99px 0 0 99px',
                            }}
                          />
                          <Typography
                            sx={{
                              color: '#404040',
                              fontSize: '9px',
                              lineHeight: '9px',
                              fontWeight: 'bold',
                              position: 'absolute',
                              right: '4px',
                              top: '0px',
                              bottom: '0px',
                              margin: 'auto'
                            }}
                          >
                            {Math.round(item.percent * 100)}%
                          </Typography>
                        </Box>
                        <Typography 
                          sx={{ 
                            fontSize: '10px',
                            lineHeight: '15px',
                            fontWeight: 'bold',
                            letterSpacing: '.05rem'
                          }}
                        >
                          {item.doc_count}件
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              width: '100%',
              gap: '12px',
              backgroundColor: '#FAFAFA',
              borderRadius: '8px',
            }}
          >
            {/* 文字雲 */}
            <Box>
              <Typography
                sx={{
                  pl: '4mm',
                  mt: '4mm',
                  borderLeft: '1.5mm solid #E95076',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  letterSpacing: '.05rem',
                  color: '#404040',
                }}
              >
                文字雲
              </Typography>
              <Box 
                sx={{ 
                  width: '90%',
                  height: 'auto',
                  margin: '0 auto'
                }}
              >
                {report && report.word_cloud && (
                  <WordCloudChart data={report.word_cloud} />
                )}
              </Box>
            </Box>
          </Box>
        </Box>

        {/* 第二頁 */}
        <Box 
          className="page page2" 
          sx={{ 
            padding: '9.5mm 10mm', 
            backgroundColor: '#ffffff',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #ACBCC8',
              pb: '3mm',
              mb: '7mm'
            }}
          >
            <ReportLogoIcon 
              style={{
                width: '35mm',
                mr: '3mm',
              }}
            />
            <Typography
              sx={{
                backgroundColor: '#F5F5F5',
                borderRadius: '99mm',
                p: '1mm 4mm',
                fontSize: '14px',
                letterSpacing: '.08rem'
              }}
            >
              {currentMinDate} ~ {currentMaxDate}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: '10px',
              position: 'absolute',
              right: '10mm',
              top: '12mm',
              color: '#737373',
              letterSpacing: '.08rem'
            }}
          >
            2/6
          </Typography>

          {/* 當期排行 */}
          <Box
            sx={{
              backgroundColor: '#FAFAFA',
              borderRadius: '8px',
              width: '100%',
              mb: '3mm'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '12px',
                mb: '3.5mm'
              }}
            >
              {/* 機關排行 */}
              <Box
                sx={{
                  backgroundColor: '#FAFAFA',
                  borderRadius: '8px',
                  width: '30%'
                }}
              >
                <Box
                  sx={{
                    pl: '4mm',
                    mt: '4mm',
                    mb: '3mm',
                    borderLeft: '1.5mm solid #52697A',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      lineHeight: 1.2,
                      letterSpacing: '.05rem',
                      color: '#404040',
                      fontFamily: 'sans-serif',
                      mb: '1mm'
                    }}
                  >
                    {currentMinDate} ~ {currentMaxDate} <br />機關排行
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '10px',
                      letterSpacing: '.03rem',
                      color: '#737373',
                    }}
                  >
                    *第10名以後因數量較少，此處不列出。
                  </Typography>
                </Box>
                {report && report.stats_detail && (
                  <Box 
                    sx={{ 
                      width: '100%', 
                      margin: '12px 0',
                      p: '0 12px'
                    }}
                  >
                    <Box 
                      sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        gap: '3px',
                      }}
                    >
                      {report.stats_detail.owner_unit?.map((item, index) => (
                        <Box 
                          key={index} 
                          sx={{ 
                            display: 'flex',
                            flexFlow: 'row wrap',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            p: '0',
                            borderBottom: '1px solid #E5E5E5'
                          }}
                        >
                          <Box 
                            sx={{ 
                              width: '140px', 
                              mr: '12px',
                              display: 'flex',
                              flexFlow: 'rowr wrap',
                              alignItems: 'center'
                            }}
                          >
                            <Box
                              sx={{
                                width: '16px',
                                height: '16px',
                                backgroundColor: '#ffffff',
                                border: '1px solid #D4D4D4',
                                borderRadius: '50%',
                                mr: '4px',
                                p: '0 3px',
                              }}
                            >
                              <Typography
                                sx={{
                                  lineHeight: '16px',
                                  textAlign: 'center',
                                  fontSize: '8px',
                                  fontWeight: 'bold',
                                  color: '#404040'
                                }}
                              >
                                {index + 1}
                              </Typography>
                            </Box>
                            <Typography 
                              sx={{ 
                                fontSize: '14px', 
                                lineHeight: '21px',
                                fontWeight: 'bold',
                              }}
                            >
                              {item.key.length > 8 ? item.key.substring(0, 8) + '...' : item.key}
                            </Typography>
                          </Box>
                          <Typography 
                            sx={{ 
                              fontSize: '12px',
                              lineHeight: '21px',
                              fontWeight: 'bold',
                              letterSpacing: '.05rem',
                              color: '#5084E9'
                            }}
                          >
                            {item.doc_count}件
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}
              </Box>

              {/* 前五多 */}
              <Box
                sx={{
                  backgroundColor: '#FAFAFA',
                  borderRadius: '8px',
                  width: 'calc(70% - 12px)'
                }}
              >
                <Typography
                  sx={{
                    pl: '4mm',
                    mt: '4mm',
                    mb: '3mm',
                    borderLeft: '1.5mm solid #52697A',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    lineHeight: 1.2,
                    letterSpacing: '.05rem',
                    color: '#404040',
                    fontFamily: 'sans-serif',
                  }}
                >
                  {currentMinDate} ~ {currentMaxDate} <br />前5多項目
                </Typography>

                <Stack
                  sx={{
                    p: '0 10px 10px'
                  }}
                >
                  {report && report.stats_detail?.owner_unit.slice(0, 5).map((ownerUnit, index) => (
                    <Box
                      key={index}
                      sx={{
                        borderBottom: '1px solid #E5E5E5',
                        display: 'flex',
                        flexFlow: 'row wrap',
                        p: '5px',
                      }}
                    >
                      <Box
                        sx={{
                          width: '140px',
                        }}
                      >
                        <Box 
                          sx={{ 
                            width: '140px', 
                            mr: '12px',
                            display: 'flex',
                            flexFlow: 'row wrap',
                            alignItems: 'center'
                          }}
                        >
                          <Box
                            sx={{
                              width: '16px',
                              height: '16px',
                              backgroundColor: '#ffffff',
                              border: '1px solid #D4D4D4',
                              borderRadius: '50%',
                              mr: '4px',
                              p: '0 3px',
                            }}
                          >
                            <Typography
                              sx={{
                                lineHeight: '16px',
                                textAlign: 'center',
                                fontSize: '8px',
                                fontWeight: 'bold',
                                color: '#404040'
                              }}
                            >
                              {index + 1}
                            </Typography>
                          </Box>
                          <Typography 
                            sx={{ 
                              color: '#5084E9',
                              fontSize: '14px', 
                              lineHeight: '21px',
                              fontWeight: 'bold',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                          >
                            {ownerUnit.key.length > 8 ? ownerUnit.key.substring(0, 8) + '...' : ownerUnit.key}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          flex: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            borderBottom: '1px solid #E5E5E5',
                            p: '3px',
                            '&:first-of-type': {
                              pt: '0'
                            },
                            '&:last-child': {
                              borderBottom: 'none'
                            }
                          }}
                        >
                          <Box
                            sx={{
                              width: '20%',
                              display: 'flex',
                              flexFlow: 'column wrap',
                            }}
                          >
                            <Typography 
                              sx={{ 
                                color: '#404040',
                                fontSize: '14px', 
                                lineHeight: '21px',
                                fontWeight: 'bold',
                              }}
                            >
                              {(ownerUnit.sub_category[0].percent_by_owern_unit * 100).toFixed(1)}%
                            </Typography>
                            <Typography 
                              sx={{ 
                                color: '#5084E9',
                                fontSize: '14px', 
                                lineHeight: '21px',
                                fontWeight: 'bold',
                              }}
                            >
                              {ownerUnit.sub_category[0].doc_count} 
                              <span style={{fontSize: '12px', marginLeft: '3px'}}>件</span>
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              width: '80%',
                              display: 'flex',
                              flexFlow: 'column wrap',
                            }}
                          >
                            <Typography 
                              sx={{ 
                                color: '#0A0A0A',
                                fontSize: '12px', 
                                lineHeight: '21px',
                                fontWeight: 'bold',
                              }}
                            >
                              {ownerUnit.sub_category[0].key}
                            </Typography>
                            <Typography 
                              sx={{ 
                                color: '#0A0A0A',
                                fontSize: '12px', 
                                lineHeight: '21px',
                              }}
                            >
                              {ownerUnit.sub_category[0].main_category}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Stack>
              </Box>
            </Box>
          </Box>
          
          {/* 前期排行 */}
          <Box
            sx={{
              backgroundColor: '#FAFAFA',
              borderRadius: '8px',
              width: '100%'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '12px',
                mb: '3.5mm'
              }}
            >
              {/* 機關排行 */}
              <Box
                sx={{
                  backgroundColor: '#FAFAFA',
                  borderRadius: '8px',
                  width: '30%'
                }}
              >
                <Box
                  sx={{
                    pl: '4mm',
                    mt: '4mm',
                    mb: '3mm',
                    borderLeft: '1.5mm solid #52697A',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 'bold',
                      lineHeight: 1.2,
                      letterSpacing: '.05rem',
                      color: '#404040',
                      fontFamily: 'sans-serif',
                      mb: '1mm'
                    }}
                  >
                    {previousMinDate} ~ {previousMaxDate} <br />機關排行
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '10px',
                      letterSpacing: '.03rem',
                      color: '#737373',
                    }}
                  >
                    *第10名以後因數量較少，此處不列出。
                  </Typography>
                </Box>
                {previousReport && previousReport.stats_detail && (
                  <Box 
                    sx={{ 
                      width: '100%', 
                      margin: '12px 0',
                      p: '0 12px'
                    }}
                  >
                    <Box 
                      sx={{ 
                        display: 'flex', 
                        flexDirection: 'column', 
                        gap: '3px',
                      }}
                    >
                      {previousReport.stats_detail.owner_unit?.map((item, index) => (
                        <Box 
                          key={index} 
                          sx={{ 
                            display: 'flex',
                            flexFlow: 'row wrap',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            p: '0',
                            borderBottom: '1px solid #E5E5E5'
                          }}
                        >
                          <Box 
                            sx={{ 
                              width: '140px', 
                              mr: '12px',
                              display: 'flex',
                              flexFlow: 'rowr wrap',
                              alignItems: 'center'
                            }}
                          >
                            <Box
                              sx={{
                                width: '16px',
                                height: '16px',
                                backgroundColor: '#ffffff',
                                border: '1px solid #D4D4D4',
                                borderRadius: '50%',
                                mr: '4px',
                                p: '0 3px',
                              }}
                            >
                              <Typography
                                sx={{
                                  lineHeight: '16px',
                                  textAlign: 'center',
                                  fontSize: '8px',
                                  fontWeight: 'bold',
                                  color: '#404040'
                                }}
                              >
                                {index + 1}
                              </Typography>
                            </Box>
                            <Typography 
                              sx={{ 
                                fontSize: '14px', 
                                lineHeight: '21px',
                                fontWeight: 'bold',
                              }}
                            >
                              {item.key.length > 8 ? item.key.substring(0, 8) + '...' : item.key}
                            </Typography>
                          </Box>
                          <Typography 
                            sx={{ 
                              fontSize: '12px',
                              lineHeight: '21px',
                              fontWeight: 'bold',
                              letterSpacing: '.05rem',
                              color: '#5084E9'
                            }}
                          >
                            {item.doc_count}件
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}
              </Box>

              {/* 前五多 */}
              <Box
                sx={{
                  backgroundColor: '#FAFAFA',
                  borderRadius: '8px',
                  width: 'calc(70% - 12px)'
                }}
              >
                <Typography
                  sx={{
                    pl: '4mm',
                    mt: '4mm',
                    mb: '3mm',
                    borderLeft: '1.5mm solid #52697A',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    lineHeight: 1.2,
                    letterSpacing: '.05rem',
                    color: '#404040',
                    fontFamily: 'sans-serif',
                  }}
                >
                  {previousMinDate} ~ {previousMaxDate} <br />前5多項目
                </Typography>

                <Stack
                  sx={{
                    p: '0 10px 10px'
                  }}
                >
                  {previousReport && previousReport.stats_detail?.owner_unit.slice(0, 5).map((ownerUnit, index) => (
                    <Box
                      key={index}
                      sx={{
                        borderBottom: '1px solid #E5E5E5',
                        display: 'flex',
                        flexFlow: 'row wrap',
                        p: '5px',
                      }}
                    >
                      <Box
                        sx={{
                          width: '140px',
                        }}
                      >
                        <Box 
                          sx={{ 
                            width: '140px', 
                            mr: '12px',
                            display: 'flex',
                            flexFlow: 'row wrap',
                            alignItems: 'center'
                          }}
                        >
                          <Box
                            sx={{
                              width: '16px',
                              height: '16px',
                              backgroundColor: '#ffffff',
                              border: '1px solid #D4D4D4',
                              borderRadius: '50%',
                              mr: '4px',
                              p: '0 3px',
                            }}
                          >
                            <Typography
                              sx={{
                                lineHeight: '16px',
                                textAlign: 'center',
                                fontSize: '8px',
                                fontWeight: 'bold',
                                color: '#404040'
                              }}
                            >
                              {index + 1}
                            </Typography>
                          </Box>
                          <Typography 
                            sx={{ 
                              color: '#5084E9',
                              fontSize: '14px', 
                              lineHeight: '21px',
                              fontWeight: 'bold',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                          >
                            {ownerUnit.key.length > 8 ? ownerUnit.key.substring(0, 8) + '...' : ownerUnit.key}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          flex: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            borderBottom: '1px solid #E5E5E5',
                            p: '3px',
                            '&:first-of-type': {
                              pt: '0'
                            },
                            '&:last-child': {
                              borderBottom: 'none'
                            }
                          }}
                        >
                          <Box
                            sx={{
                              width: '20%',
                              display: 'flex',
                              flexFlow: 'column wrap',
                            }}
                          >
                            <Typography 
                              sx={{ 
                                color: '#404040',
                                fontSize: '14px', 
                                lineHeight: '21px',
                                fontWeight: 'bold',
                              }}
                            >
                              {(ownerUnit.sub_category[0].percent_by_owern_unit * 100).toFixed(1)}%
                            </Typography>
                            <Typography 
                              sx={{ 
                                color: '#5084E9',
                                fontSize: '14px', 
                                lineHeight: '21px',
                                fontWeight: 'bold',
                              }}
                            >
                              {ownerUnit.sub_category[0].doc_count} 
                              <span style={{fontSize: '12px', marginLeft: '3px'}}>件</span>
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              width: '80%',
                              display: 'flex',
                              flexFlow: 'column wrap',
                            }}
                          >
                            <Typography 
                              sx={{ 
                                color: '#0A0A0A',
                                fontSize: '12px', 
                                lineHeight: '21px',
                                fontWeight: 'bold',
                              }}
                            >
                              {ownerUnit.sub_category[0].key}
                            </Typography>
                            <Typography 
                              sx={{ 
                                color: '#0A0A0A',
                                fontSize: '12px', 
                                lineHeight: '21px',
                              }}
                            >
                              {ownerUnit.sub_category[0].main_category}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Stack>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* 第三頁 */}
        <Box 
          className="page page3" 
          sx={{ 
            padding: '9.5mm 10mm', 
            backgroundColor: '#ffffff',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #ACBCC8',
              pb: '3mm',
              mb: '7mm'
            }}
          >
            <ReportLogoIcon 
              style={{
                width: '35mm',
                mr: '3mm',
              }}
            />
            <Typography
              sx={{
                backgroundColor: '#F5F5F5',
                borderRadius: '99mm',
                p: '1mm 4mm',
                fontSize: '14px',
                letterSpacing: '.08rem'
              }}
            >
              {currentMinDate} ~ {currentMaxDate}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: '10px',
              position: 'absolute',
              right: '10mm',
              top: '12mm',
              color: '#737373',
              letterSpacing: '.08rem'
            }}
          >
            3/6
          </Typography>

          <Box
            sx={{
              backgroundColor: '#FAFAFA',
              borderRadius: '8px',
              width: '100%'
            }}
          >
            <Box
              sx={{
                pl: '4mm',
                mt: '4mm',
                mb: '3mm',
                borderLeft: '1.5mm solid #52697A',
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  lineHeight: 1.2,
                  letterSpacing: '.05rem',
                  color: '#404040',
                  fontFamily: 'sans-serif',
                  mb: '1mm'
                }}
              >
                {currentMinDate} ~ {currentMaxDate} <br />1999接獲民眾諮詢及轉錄市政信箱陳情次數
              </Typography>
            </Box>

            {/* 案件陳情次數 */}
            <Stack
              sx={{
                p: '0 10px'
              }}
            >
              {report && report.stats_detail?.owner_unit.slice(0, 5).map((ownerUnit, index) => (
                <Box
                  key={index}
                  sx={{
                    borderBottom: '1px solid #E5E5E5',
                    display: 'flex',
                    flexFlow: 'row wrap',
                    p: '10px',
                    '&:last-child': {
                      borderBottom: 'none'
                    }
                  }}
                >
                  <Box
                    sx={{
                      width: '150px',
                    }}
                  >
                    <Box 
                      sx={{ 
                        width: '150px', 
                        mr: '12px',
                        display: 'flex',
                        flexFlow: 'row wrap',
                        alignItems: 'center'
                      }}
                    >
                      <Box
                        sx={{
                          width: '16px',
                          height: '16px',
                          backgroundColor: '#ffffff',
                          border: '1px solid #D4D4D4',
                          borderRadius: '50%',
                          mr: '4px',
                          p: '0 3px',
                        }}
                      >
                        <Typography
                          sx={{
                            lineHeight: '16px',
                            textAlign: 'center',
                            fontSize: '8px',
                            fontWeight: 'bold',
                            color: '#404040'
                          }}
                        >
                          {index + 1}
                        </Typography>
                      </Box>
                      <Typography 
                        sx={{ 
                          color: '#5084E9',
                          fontSize: '14px', 
                          lineHeight: '21px',
                          fontWeight: 'bold',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {ownerUnit.key.length > 8 ? ownerUnit.key.substring(0, 8) + '...' : ownerUnit.key}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                    }}
                  >
                    {ownerUnit.sub_category?.map((subCategoryItem, subIndex) => (
                      <Box
                        key={subIndex}
                        sx={{
                          display: 'flex',
                          borderBottom: '1px solid #E5E5E5',
                          p: '5.5px',
                          '&:first-of-type': {
                            pt: '0'
                          },
                          '&:last-child': {
                            borderBottom: 'none'
                          }
                        }}
                      >
                        <Typography 
                          sx={{ 
                            color: '#0A0A0A',
                            fontSize: '12px', 
                            lineHeight: '21px',
                            fontWeight: 'bold',
                            width: '45%'
                          }}
                        >
                          {subCategoryItem.key}
                        </Typography>
                        <Typography 
                          sx={{ 
                            color: '#0A0A0A',
                            fontSize: '12px', 
                            lineHeight: '21px',
                            width: '45%',
                            textAlign: 'left'
                          }}
                        >
                          {subCategoryItem.main_category}
                        </Typography>
                        <Typography 
                          sx={{ 
                            color: '#5084E9',
                            fontSize: '14px', 
                            lineHeight: '21px',
                            fontWeight: 'bold',
                            width: '10%',
                            textAlign: 'right'
                          }}
                        >
                          {subCategoryItem.doc_count} 
                          <span style={{fontSize: '12px', marginLeft: '3px'}}>件</span>
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              ))}
            </Stack>

          </Box>
        </Box>

        {/* 第四頁 */}
        <Box 
          className="page page4" 
          sx={{ 
            padding: '9.5mm 10mm', 
            backgroundColor: '#ffffff',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #ACBCC8',
              pb: '3mm',
              mb: '7mm'
            }}
          >
            <ReportLogoIcon 
              style={{
                width: '35mm',
                mr: '3mm',
              }}
            />
            <Typography
              sx={{
                backgroundColor: '#F5F5F5',
                borderRadius: '99mm',
                p: '1mm 4mm',
                fontSize: '14px',
                letterSpacing: '.08rem'
              }}
            >
              {currentMinDate} ~ {currentMaxDate}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: '10px',
              position: 'absolute',
              right: '10mm',
              top: '12mm',
              color: '#737373',
              letterSpacing: '.08rem'
            }}
          >
            4/6
          </Typography>

          <Box
            sx={{
              backgroundColor: '#FAFAFA',
              borderRadius: '8px',
              width: '100%'
            }}
          >
            <Box
              sx={{
                pl: '4mm',
                mt: '4mm',
                mb: '3mm',
                borderLeft: '1.5mm solid #52697A',
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  lineHeight: 1.2,
                  letterSpacing: '.05rem',
                  color: '#404040',
                  fontFamily: 'sans-serif',
                  mb: '1mm'
                }}
              >
                {currentMinDate} ~ {currentMaxDate} <br />1999接獲民眾諮詢及轉錄市政信箱陳情次數
              </Typography>
            </Box>

            {/* 案件陳情次數 */}
            <Stack
              sx={{
                p: '0 10px'
              }}
            >
              {report && report.stats_detail?.owner_unit.slice(5, 10).map((ownerUnit, index) => (
                <Box
                  key={index}
                  sx={{
                    borderBottom: '1px solid #E5E5E5',
                    display: 'flex',
                    flexFlow: 'row wrap',
                    p: '10px',
                    '&:last-child': {
                      borderBottom: 'none'
                    }
                  }}
                >
                  <Box
                    sx={{
                      width: '150px',
                    }}
                  >
                    <Box 
                      sx={{ 
                        width: '150px', 
                        mr: '12px',
                        display: 'flex',
                        flexFlow: 'row wrap',
                        alignItems: 'center'
                      }}
                    >
                      <Box
                        sx={{
                          width: '16px',
                          height: '16px',
                          backgroundColor: '#ffffff',
                          border: '1px solid #D4D4D4',
                          borderRadius: '50%',
                          mr: '4px',
                          p: '0 3px',
                        }}
                      >
                        <Typography
                          sx={{
                            lineHeight: '16px',
                            textAlign: 'center',
                            fontSize: '8px',
                            fontWeight: 'bold',
                            color: '#404040'
                          }}
                        >
                          {index + 6}
                        </Typography>
                      </Box>
                      <Typography 
                        sx={{ 
                          color: '#5084E9',
                          fontSize: '14px', 
                          lineHeight: '21px',
                          fontWeight: 'bold',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {ownerUnit.key.length > 8 ? ownerUnit.key.substring(0, 8) + '...' : ownerUnit.key}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                    }}
                  >
                    {ownerUnit.sub_category?.map((subCategoryItem, subIndex) => (
                      <Box
                        key={subIndex}
                        sx={{
                          display: 'flex',
                          borderBottom: '1px solid #E5E5E5',
                          p: '5.5px',
                          '&:first-of-type': {
                            pt: '0'
                          },
                          '&:last-child': {
                            borderBottom: 'none'
                          }
                        }}
                      >
                        <Typography 
                          sx={{ 
                            color: '#0A0A0A',
                            fontSize: '12px', 
                            lineHeight: '21px',
                            fontWeight: 'bold',
                            width: '45%'
                          }}
                        >
                          {subCategoryItem.key}
                        </Typography>
                        <Typography 
                          sx={{ 
                            color: '#0A0A0A',
                            fontSize: '12px', 
                            lineHeight: '21px',
                            width: '45%',
                            textAlign: 'left'
                          }}
                        >
                          {subCategoryItem.main_category}
                        </Typography>
                        <Typography 
                          sx={{ 
                            color: '#5084E9',
                            fontSize: '14px', 
                            lineHeight: '21px',
                            fontWeight: 'bold',
                            width: '10%',
                            textAlign: 'right'
                          }}
                        >
                          {subCategoryItem.doc_count} 
                          <span style={{fontSize: '12px', marginLeft: '3px'}}>件</span>
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              ))}
            </Stack>

          </Box>
        </Box>

        {/* 第五頁 */}
        <Box 
          className="page page5" 
          sx={{ 
            padding: '9.5mm 10mm', 
            backgroundColor: '#ffffff',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #ACBCC8',
              pb: '3mm',
              mb: '7mm'
            }}
          >
            <ReportLogoIcon 
              style={{
                width: '35mm',
                mr: '3mm',
              }}
            />
            <Typography
              sx={{
                backgroundColor: '#F5F5F5',
                borderRadius: '99mm',
                p: '1mm 4mm',
                fontSize: '14px',
                letterSpacing: '.08rem'
              }}
            >
              {currentMinDate} ~ {currentMaxDate}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: '10px',
              position: 'absolute',
              right: '10mm',
              top: '12mm',
              color: '#737373',
              letterSpacing: '.08rem'
            }}
          >
            5/6
          </Typography>

          <Box
            sx={{
              backgroundColor: '#FAFAFA',
              borderRadius: '8px',
              width: '100%'
            }}
          >
            <Box
              sx={{
                pl: '4mm',
                mt: '4mm',
                mb: '3mm',
                borderLeft: '1.5mm solid #52697A',
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  lineHeight: 1.2,
                  letterSpacing: '.05rem',
                  color: '#404040',
                  fontFamily: 'sans-serif',
                  mb: '1mm'
                }}
              >
                {previousMinDate} ~ {previousMaxDate} <br />1999接獲民眾諮詢及轉錄市政信箱陳情次數
              </Typography>
            </Box>

            {/* 案件陳情次數 */}
            <Stack
              sx={{
                p: '0 10px'
              }}
            >
              {previousReport && previousReport.stats_detail?.owner_unit.slice(0, 5).map((ownerUnit, index) => (
                <Box
                  key={index}
                  sx={{
                    borderBottom: '1px solid #E5E5E5',
                    display: 'flex',
                    flexFlow: 'row wrap',
                    p: '10px',
                    '&:last-child': {
                      borderBottom: 'none'
                    }
                  }}
                >
                  <Box
                    sx={{
                      width: '150px',
                    }}
                  >
                    <Box 
                      sx={{ 
                        width: '150px', 
                        mr: '12px',
                        display: 'flex',
                        flexFlow: 'row wrap',
                        alignItems: 'center'
                      }}
                    >
                      <Box
                        sx={{
                          width: '16px',
                          height: '16px',
                          backgroundColor: '#ffffff',
                          border: '1px solid #D4D4D4',
                          borderRadius: '50%',
                          mr: '4px',
                          p: '0 3px',
                        }}
                      >
                        <Typography
                          sx={{
                            lineHeight: '16px',
                            textAlign: 'center',
                            fontSize: '8px',
                            fontWeight: 'bold',
                            color: '#404040'
                          }}
                        >
                          {index + 1}
                        </Typography>
                      </Box>
                      <Typography 
                        sx={{ 
                          color: '#5084E9',
                          fontSize: '14px', 
                          lineHeight: '21px',
                          fontWeight: 'bold',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {ownerUnit.key.length > 8 ? ownerUnit.key.substring(0, 8) + '...' : ownerUnit.key}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                    }}
                  >
                    {ownerUnit.sub_category?.map((subCategoryItem, subIndex) => (
                      <Box
                        key={subIndex}
                        sx={{
                          display: 'flex',
                          borderBottom: '1px solid #E5E5E5',
                          p: '5.5px',
                          '&:first-of-type': {
                            pt: '0'
                          },
                          '&:last-child': {
                            borderBottom: 'none'
                          }
                        }}
                      >
                        <Typography 
                          sx={{ 
                            color: '#0A0A0A',
                            fontSize: '12px', 
                            lineHeight: '21px',
                            fontWeight: 'bold',
                            width: '45%'
                          }}
                        >
                          {subCategoryItem.key}
                        </Typography>
                        <Typography 
                          sx={{ 
                            color: '#0A0A0A',
                            fontSize: '12px', 
                            lineHeight: '21px',
                            width: '45%',
                            textAlign: 'left'
                          }}
                        >
                          {subCategoryItem.main_category}
                        </Typography>
                        <Typography 
                          sx={{ 
                            color: '#5084E9',
                            fontSize: '14px', 
                            lineHeight: '21px',
                            fontWeight: 'bold',
                            width: '10%',
                            textAlign: 'right'
                          }}
                        >
                          {subCategoryItem.doc_count} 
                          <span style={{fontSize: '12px', marginLeft: '3px'}}>件</span>
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              ))}
            </Stack>

          </Box>
        </Box>

        {/* 第六頁 */}
        <Box 
          className="page page6" 
          sx={{ 
            padding: '9.5mm 10mm', 
            backgroundColor: '#ffffff',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #ACBCC8',
              pb: '3mm',
              mb: '7mm'
            }}
          >
            <ReportLogoIcon 
              style={{
                width: '35mm',
                mr: '3mm',
              }}
            />
            <Typography
              sx={{
                backgroundColor: '#F5F5F5',
                borderRadius: '99mm',
                p: '1mm 4mm',
                fontSize: '14px',
                letterSpacing: '.08rem'
              }}
            >
              {currentMinDate} ~ {currentMaxDate}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: '10px',
              position: 'absolute',
              right: '10mm',
              top: '12mm',
              color: '#737373',
              letterSpacing: '.08rem'
            }}
          >
            6/6
          </Typography>

          <Box
            sx={{
              backgroundColor: '#FAFAFA',
              borderRadius: '8px',
              width: '100%'
            }}
          >
            <Box
              sx={{
                pl: '4mm',
                mt: '4mm',
                mb: '3mm',
                borderLeft: '1.5mm solid #52697A',
              }}
            >
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                  lineHeight: 1.2,
                  letterSpacing: '.05rem',
                  color: '#404040',
                  fontFamily: 'sans-serif',
                  mb: '1mm'
                }}
              >
                {previousMinDate} ~ {previousMaxDate} <br />1999接獲民眾諮詢及轉錄市政信箱陳情次數
              </Typography>
            </Box>

            {/* 案件陳情次數 */}
            <Stack
              sx={{
                p: '0 10px'
              }}
            >
              {previousReport && previousReport.stats_detail?.owner_unit.slice(5, 10).map((ownerUnit, index) => (
                <Box
                  key={index}
                  sx={{
                    borderBottom: '1px solid #E5E5E5',
                    display: 'flex',
                    flexFlow: 'row wrap',
                    p: '10px',
                    '&:last-child': {
                      borderBottom: 'none'
                    }
                  }}
                >
                  <Box
                    sx={{
                      width: '150px',
                    }}
                  >
                    <Box 
                      sx={{ 
                        width: '150px', 
                        mr: '12px',
                        display: 'flex',
                        flexFlow: 'row wrap',
                        alignItems: 'center'
                      }}
                    >
                      <Box
                        sx={{
                          width: '16px',
                          height: '16px',
                          backgroundColor: '#ffffff',
                          border: '1px solid #D4D4D4',
                          borderRadius: '50%',
                          mr: '4px',
                          p: '0 3px',
                        }}
                      >
                        <Typography
                          sx={{
                            lineHeight: '16px',
                            textAlign: 'center',
                            fontSize: '8px',
                            fontWeight: 'bold',
                            color: '#404040'
                          }}
                        >
                          {index + 6}
                        </Typography>
                      </Box>
                      <Typography 
                        sx={{ 
                          color: '#5084E9',
                          fontSize: '14px', 
                          lineHeight: '21px',
                          fontWeight: 'bold',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {ownerUnit.key.length > 8 ? ownerUnit.key.substring(0, 8) + '...' : ownerUnit.key}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                    }}
                  >
                    {ownerUnit.sub_category?.map((subCategoryItem, subIndex) => (
                      <Box
                        key={subIndex}
                        sx={{
                          display: 'flex',
                          borderBottom: '1px solid #E5E5E5',
                          p: '5.5px',
                          '&:first-of-type': {
                            pt: '0'
                          },
                          '&:last-child': {
                            borderBottom: 'none'
                          }
                        }}
                      >
                        <Typography 
                          sx={{ 
                            color: '#0A0A0A',
                            fontSize: '12px', 
                            lineHeight: '21px',
                            fontWeight: 'bold',
                            width: '45%'
                          }}
                        >
                          {subCategoryItem.key}
                        </Typography>
                        <Typography 
                          sx={{ 
                            color: '#0A0A0A',
                            fontSize: '12px', 
                            lineHeight: '21px',
                            width: '45%',
                            textAlign: 'left'
                          }}
                        >
                          {subCategoryItem.main_category}
                        </Typography>
                        <Typography 
                          sx={{ 
                            color: '#5084E9',
                            fontSize: '14px', 
                            lineHeight: '21px',
                            fontWeight: 'bold',
                            width: '10%',
                            textAlign: 'right'
                          }}
                        >
                          {subCategoryItem.doc_count} 
                          <span style={{fontSize: '12px', marginLeft: '3px'}}>件</span>
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              ))}
            </Stack>

          </Box>
        </Box>
      </Box>
    </Stack>
  );
};

export default ExportWeekReport;