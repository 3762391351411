import axios from "axios";

const axiosInstance = axios.create({
	baseURL:
		process.env.REACT_APP_RUNTIME_ENV === "PROD"
			? process.env.REACT_APP_PROD_API_URL
			: process.env.REACT_APP_DEV_API_URL,
});

// const axiosInstance = axios.create({
// 	baseURL: "http://127.0.0.1:8000",
// });

axiosInstance.interceptors.request.use(
	(config) => {
		const token = localStorage.getItem("accessToken");
		if (token) {
			config.headers.Authorization = `Bearer ${token}`;
		}
		return config;
	},
	(error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
	(response) => response,
	(error) => Promise.reject(error)
);

export default axiosInstance;
