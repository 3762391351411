import axiosInstance from '@/api/axiosConfig';

// 記錄已獲取過的 chunk
let fetchedChunks = {};

export const clearFetchedChunks = () => {
  fetchedChunks = {};
};

// 取得第一個 chunk 案件資料
export const getCasesFirst = async (formattedMinDate, formattedMaxDate, ownerUnitSelected, subCategoriesSelected, searchSelected, satisfaction, chunkIndex, search_after = null, orderField, orderType) => {
  try {
    let allData = [];
    let totalCount = 0;
    let totalChunk = 0;
    let previous_period_total_count = 0;
    let previous_period = {};
    let current_period = {};
    let owner_unit = {};
    let categories = {};

    clearFetchedChunks();

    // 第一次
    const response = await axiosInstance.post('/api/data/trend_analysis/', {
      "start_time": formattedMinDate || '',
      "end_time": formattedMaxDate || '',
      "category": subCategoriesSelected || {},
      "owner_unit": ownerUnitSelected || {},
      "search": searchSelected || '',
      "satisfaction": satisfaction || [],
      "search_after": null,
      "order_field": orderField || "record_time",
      "order": orderType || "desc"
    });

    if (response?.data?.data) {
      const data = response.data.data;
      allData = allData.concat(data.table_data || allData);
      totalCount = data.total_count || totalCount;
      totalChunk = data.total_chunk || totalChunk;
      previous_period_total_count = data.petition_count?.previous_period_total_count || previous_period_total_count;
      previous_period = data.petition_count?.previous_period || previous_period;
      current_period = data.petition_count?.current_period || current_period;
      owner_unit = data.owner_unit || owner_unit;
      categories = data.categories || categories;
      search_after = data.search_after;
      fetchedChunks[chunkIndex] = data.table_data || [];
    }

    return { responseData: allData, totalCount, totalChunk, previous_period, current_period, previous_period_total_count, owner_unit, categories, search_after };
  } catch (error) {
    if (error?.response?.data && error.response?.status === 400) {
      throw new Error(error.response.data.message);
    }
  }
}

// 取得其他 chunk 案件資料
export const getCases = async (formattedMinDate, formattedMaxDate, ownerUnitSelected, subCategoriesSelected, searchSelected, satisfaction, chunkIndex, search_after = null, orderField, orderType) => {
  try {
    let allData = [];

    for (let i = 2; i <= chunkIndex; i++) {
      if (!fetchedChunks[i]) {
        const response = await axiosInstance.post('/api/data/trend_analysis/', {
          "start_time": formattedMinDate || '',
          "end_time": formattedMaxDate || '',
          "category": subCategoriesSelected || {},
          "owner_unit": ownerUnitSelected || {},
          "search": searchSelected || '',
          "satisfaction": satisfaction || [],
          "search_after": search_after || null,
          "order_field": orderField || "record_time",
          "order": orderType || "desc"
        });

        // console.log('API response', response);
    
        if (response?.data?.data) {
          const data = response.data.data;
          allData = allData.concat(data.table_data || allData);
          search_after = data.search_after;
          fetchedChunks[i] = data.table_data || [];
        }
      } else {
        allData = allData.concat(fetchedChunks[i]);
      }
    }

    return { responseData: allData, search_after, fetchedChunks };
  } catch (error) {
    console.log(error)
  }
}

// 取得案件檢視內容
export const getCaseDetail = async (formattedMinDate, formattedMaxDate, caseId) => {
  try {
    if (caseId.length !== 0) {
      const response = await axiosInstance.post('/api/data/trend_analysis/case/detail/', {
        "start_time": formattedMinDate,
        "end_time": formattedMaxDate,
        "case_ids": caseId
      });
      
      return response
    }
  } catch (error) {
    console.log(error)
  }
}