import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
	Button,
	IconButton,
	OutlinedInput,
	Typography,
	InputAdornment,
	FormControl,
	Box,
} from "@mui/material";
import Fallback from "@/components/Fallback";
import { styled } from "@mui/material/styles";
import { forgotPassword, resetPassword } from '@/api/loginAPI'
import StyledAlert from '@/components/StyledAlert'
import { ReactComponent as EyeOpenIcon } from "@/assets/eye.svg";
import { ReactComponent as EyeCloseIcon } from "@/assets/eyeClose.svg";

const StyledButton = styled(Button)({
	width: "240px",
	marginTop: "10px",
});

const StyledIconButton = styled(IconButton)({
	svg: {
		width: "20px",
		height: "20px",
	},
});

function Password() {
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
	const [isResetSuccess, setIsResetSuccess] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [passwordAgain, setPasswordAgain] = useState({
		password: "",
		showPassword: false,
	});;
	const [errorMsg, setErrorMsg] = useState("");
	const [showAlert, setShowAlert] = useState(false);

	const navigate = useNavigate();
	const { pathname } = useLocation()

	const thisYear = new Date().getFullYear();

	const useHandleQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

	const query = useHandleQuery();
	const token = query.get('token');

	useEffect(() => {
		if (errorMsg) {
			setTimeout(() => {
				setShowAlert(false);
				setErrorMsg("");
			}, 2000);

			setShowAlert(true);
		}
	}, [errorMsg]);

	const handleSubmit = async () => {
		if (pathname === '/user/password/forgot') {
			// check email
			const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			if (!email) {
				setErrorMsg("電子信箱不得為空");
				return;
			} else if (!emailRegex.test(email)) {
				setErrorMsg("請輸入有效的電子郵件地址");
				return;
			}
		} else {
			// check new password
			if (!password || !passwordAgain.password) {
				setErrorMsg("密碼欄位不得為空");
				return;
			} else if (password !== passwordAgain.password) {
				setErrorMsg("密碼不一致");
				return;
			}
		}

		if (errorMsg) {
			setIsSubmitLoading(false);
		} else {
			setIsSubmitLoading(true);
			if (pathname === '/user/password/forgot') {
				try {
					await forgotPassword(email);
					setIsSubmitLoading(false)
					setIsSubmitSuccess(true)
				} catch (error) {
					if (error.response.status === 404){
						setErrorMsg("請輸入正確的電子郵件地址")
						setIsSubmitLoading(false)
						setIsSubmitSuccess(false)
					}
				}
			} else {
				try {
					await resetPassword(token, password);
					setIsSubmitLoading(false)
					setIsResetSuccess(true)
				} catch (error) {
					if (error.response.status === 400){
						setErrorMsg("token已失效")
						setIsSubmitLoading(false)
						setIsResetSuccess(false)
						setTimeout(() => {
							navigate('/dashboard')
						}, 2000)
					}
				}
			}
		}
	};

	const handleClickShowPassword = () => {
		setPasswordAgain({
			...passwordAgain,
			showPassword: !passwordAgain.showPassword,
		});
	};

	const handleLogin = () => {
		navigate('/')
	}

	return (
		<>
			{isSubmitLoading && <Fallback />}
			<Box
				component="section"
				sx={{
					padding: 0,
					mt: '20rem',
				}}
			>
				<Box
					sx={{
						textAlign: "center",
						display: "flex",
						flexFlow: "column wrap",
						alignItems: "center",
					}}
				>
					<Typography
						variant="h3"
						sx={{
							color: '#52697A',
							fontWeight: 700,
							marginTop: "40px",
							marginBottom: "5px",
						}}
					>
						{isSubmitSuccess || isResetSuccess ? "送出成功" : (pathname === '/user/password/update' ? "重設密碼" : "忘記密碼")}
					</Typography>
					<Typography
						variant="h4"
						sx={{
							color: (theme) => theme.palette.neutral.grey,
							marginBottom: isSubmitSuccess || isResetSuccess ? "14px" : "20px",
							fontWeight: 400
						}}
					> 
						{pathname === '/user/password/update'? "" : (isSubmitSuccess ? "重設密碼連結將會寄至您的信箱" : "請輸入您的電子信箱")}
					</Typography>

					{/* Submit form */}
					{!isSubmitSuccess && pathname === '/user/password/forgot' &&
						<FormControl sx={{ marginBottom: "19px" }}>
							<OutlinedInput
								onChange={(e) => setEmail(e.target.value)}
								value={email}
								placeholder="輸入電子信箱"
							/>
						</FormControl>
					}
					{
						!isResetSuccess && pathname === '/user/password/update' &&
						<>
							<FormControl sx={{ marginBottom: "19px" }}>
								<OutlinedInput
									onChange={(e) => setPassword(e.target.value)}
									value={password}
									placeholder="輸入新的密碼"
								/>
							</FormControl>
							<FormControl sx={{ marginBottom: "19px" }}>
								<OutlinedInput
									id="outlined-adornment-password"
									type={
										passwordAgain.showPassword ? "text" : "password"
									}
									value={passwordAgain.password}
									placeholder="再次輸入密碼"
									onChange={(e) =>
										setPasswordAgain({
											...passwordAgain,
											password: e.target.value,
										})
									}
									endAdornment={
										<InputAdornment position="end">
											<StyledIconButton
												aria-label="toggle password visibility"
												onClick={handleClickShowPassword}
												edge="end"
												className="eye-icon"
											>
												{passwordAgain.showPassword ? (
													<EyeOpenIcon />
												) : (
													<EyeCloseIcon />
												)}
											</StyledIconButton>
										</InputAdornment>
									}
								/>
							</FormControl>
						</>
					}
					
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
						}}
					>
						<StyledButton
							onClick={isSubmitSuccess || isResetSuccess ? handleLogin : handleSubmit}
							disabled={isSubmitLoading}
						>
							{isSubmitSuccess || isResetSuccess ? "重新登入" : "送出"}
						</StyledButton>
					</Box>
				</Box>
			</Box>

			{/* footer copyright */}
			<Box
				sx={{
					textAlign: "center",
					mt: isSubmitSuccess || isResetSuccess ? '26.3rem' : '22rem'
				}}
			>
				<Typography
					variant="h5"
					sx={{ 
						color: (theme) => theme.palette.neutral.dark ,
						fontWeight: 400
					}}
				>
					© {thisYear} Taoyuan City Government. All rights reserved.
				</Typography>
			</Box>

			{/* error message alert */}
			{showAlert && (
				<StyledAlert severity="error">{errorMsg}</StyledAlert>
			)}
		</>
	);
}

export default Password;
