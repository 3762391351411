import React from 'react'
import { Grid } from '@mui/material'
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles'

const StyledGridItem = styled(Grid)({
  width: '100%',
  height: '100vh',
  position: 'absolute',
  zIndex: 100,
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: 'rgba(10, 10, 10, .25)',
  backdropFilter: 'blur(5px)'
})

function Fallback() {
  return (
    <Grid
      container
      sx={{
        placeItems: 'center', justifyContent: 'center', width: '100%', height: '100%',
      }}
    >
      <StyledGridItem item>
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: 'rgba(253, 237, 241, 1)',
            animationDuration: '600ms',
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          size={200}
          thickness={2}
        />
      </StyledGridItem>
    </Grid>
  )
}

export default Fallback