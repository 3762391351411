import { create } from 'zustand';

const UseSearchStore = create((set) => ({
  searchSelected: '',
  // 更新搜尋狀態
  updateSearchSelected: (value) => { 
    set({ searchSelected: value })
  },
  // 清空搜尋
  clearSearchSelected: () => { 
    set({ searchSelected: '' })
  }
}))

export default UseSearchStore;