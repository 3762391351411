import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Box } from '@mui/material'

const ColumnChart = ({categories, series}) => {
  const options = {
    chart: {
      type: 'column',
    },
    title: {
      text: '前後期分析'
    },
    xAxis: {
      categories: categories,
      crosshair: true,
      gridLineWidth: 1,
      gridLineColor: '#e0e0e0',
      gridLineDashStyle: 'Dash',
      labels: {
        formatter: function () {
          const dates = this.value.split(' - ');
          return `
            <div style="display: flex; flexFlow: row wrap; justifyContent: space-between; ">
              <span style="color: #52697A; fontWeight: bold;">${dates[0]}</span>
              <span style="color: #5084E9; fontWeight: bold;">${dates[1]}</span>
            </div>
          `;
        },
        style: {
          whiteSpace: 'nowrap'
        }
      }
    },
    yAxis: {
        min: 0, 
        title: {
          text: ''
        },
        gridLineWidth: 1, 
        gridLineColor: '#e0e0e0',
        gridLineDashStyle: 'Dash',
        tickInterval: 50,
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y}</b></td></tr>',
      footerFormat: '</table>',
      useHTML: true
    },

    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
        dataLabels: {
            enabled: true,
            format: '{point.y}',
            style: {
              fontSize: '10px'
            }
        }
      }
    },
    series: series
  };

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        sx={{
          width: '90%'
        }}
      />
    </Box>
  );
};

export default ColumnChart
