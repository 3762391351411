import axiosInstance from '@/api/axiosConfig';

export const getHomeData = async (formattedMinDate, formattedMaxDate) => {
  try {
    const response = await axiosInstance.post('/api/data/home/', {
      "start_time": formattedMinDate || '',
      "end_time": formattedMaxDate || '',
    });
    
    return response
  } catch (error) {
    console.log(error)
  }
}

