import { create } from 'zustand';

const UseSatisfactionStore = create((set) => ({
  satisfaction: [],
  // 更新滿意度查詢狀態
  updateSatisfaction: (value) => set((state) => {
    return { satisfaction: value }
  }),
}))

export default UseSatisfactionStore;