import React, { useEffect, useState } from 'react'
import { Box, IconButton, SvgIcon, Typography, Popover, Button, Stack } from '@mui/material'
import { styled } from '@mui/material/styles'
import { DateRangePicker } from 'react-date-range'
import { DateTime } from 'luxon'
import { createStaticRanges } from 'react-date-range'
import { subDays, format } from 'date-fns'
import dateRangePickerRanges from '@/utils/dateRangePickerRanges'
import useDateRangePicker from '@/hooks/useDateRangePicker'
import UseDateRangeStore from '@/hooks/UseDateRangeStore'
import locale from 'date-fns/locale/zh-TW';

import { ReactComponent as DrawerCloseIcon } from '@/assets/drawerClose.svg'
import { ReactComponent as DrawerOpenIcon } from '@/assets/drawerOpen.svg'
import { ReactComponent as CalendarIcon } from '@/assets/calendar.svg'
import { ReactComponent as ResetDateIcon } from '@/assets/resetDate.svg'
import HeaderLogoIcon from '@/assets/header-logo.svg'
import ExportWeekReport from '@/components/ExportWeekReport'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

const openedMixin = (theme, width) => ({
  width,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme, width) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: width - 12,
  [theme.breakpoints.up('sm')]: {
    width,
  },
})

const ToggleButtonBox = styled(Box)(
  ({ theme, open, top }) => ({
    // position: 'absolute',
    // top,
    // zIndex: '1300',
    // right: -12,
    
    // marginLeft: 11,
    // textAlign: 'right',
    ...(open && {
      ...openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
    }),
  }),
)

const StyledDateRangePicker = styled(DateRangePicker)(({ theme }) => ({
  flexWrap: 'wrap',
  '& .rdrDefinedRangesWrapper': {
    flexGrow: 1,
    '& .rdrStaticRanges': {
      flexDirection: 'row',
      flexWrap: 'wrap',
      button: {
        width: '100%',
      },
    },
  },
}))

const StyledDateRangePickerButton = styled(Button)(({ theme }) => ({
  width: '68px',
  height: '45px',
  padding: 0,
  margin: '0 5px',
  backgroundColor: '#D7DFE4',
  color: theme.palette.neutral.dark,
  border: '1px solid #D7DFE4', 
  '&:hover': {
    backgroundColor: '#D7DFE4',
    color: theme.palette.neutral.dark,
  }
}))

function Header({ width, closedWidth, height, open, drawerOnTrigger, children }) {
  const [anchorEl, setAnchorEl] = useState(null)
  const [displayDateRange, setDisplayDateRange] = useState('')
  const openDatePicker = Boolean(anchorEl)
  const id = openDatePicker ? 'popover' : undefined
  
  
  const [queryTimeRange, setQueryTimeRange] = useState({
    min: DateTime.now().startOf('day').plus({ days: -7 }),
    max: DateTime.now().endOf('day').plus({ days: -1 }),
  })
  
  const { formattedMinDate, formattedMaxDate, setFormattedDates } = UseDateRangeStore();
  const {
    dateState, handleSearch, handleSelect, handleDateChange,
  } = useDateRangePicker({
    startDate: queryTimeRange.min.toJSDate(),
    endDate: queryTimeRange.max.toJSDate(),
    onSearch: ({ validStartDate, validEndDate }) => {
      setAnchorEl(null)
  
      const newTimeMin = DateTime.fromJSDate(validStartDate)
      const newTimeMax = DateTime.fromJSDate(validEndDate)

      setQueryTimeRange({
        min: newTimeMin,
        max: newTimeMax,
      })

      localStorage.setItem('formattedMinDate', newTimeMin.toISO());
      localStorage.setItem('formattedMaxDate', newTimeMax.toISO());
    },
  })

  useEffect(() => {
    const storedMinDate = localStorage.getItem('formattedMinDate');
    const storedMaxDate = localStorage.getItem('formattedMaxDate');

    if (storedMinDate && storedMaxDate) {
      const minDate = DateTime.fromISO(storedMinDate);
      const maxDate = DateTime.fromISO(storedMaxDate);
      
      setQueryTimeRange({
        min: minDate,
        max: maxDate,
      });

      setFormattedDates(minDate.toFormat('yyyy/MM/dd'), maxDate.toFormat('yyyy/MM/dd'));
    }
  }, [setFormattedDates]);

  const handleClick = (event) => {
    handleDateChange({
      startDate: queryTimeRange.min.toJSDate(),
      endDate: queryTimeRange.max.toJSDate(),
    })
    setAnchorEl(event.currentTarget)
  }

  const handleResetDate = (event) => {
    handleDateChange({
      startDate: DateTime.now().startOf('day').plus({ days: -7 }).toJSDate(),
      endDate: DateTime.now().endOf('day').plus({ days: -1 }).toJSDate(),
    })
  }

  const customLocale = {
    ...locale,
    options: {
      ...locale.options,
      weekStartsOn: 7,
    },
    localize: {
      ...locale.localize,
      month: (n) => [
        '一月', '二月', '三月', '四月', '五月', '六月',
        '七月', '八月', '九月', '十月', '十一月', '十二月'
      ][n],
      day: (n) => ['日', '一', '二', '三', '四', '五', '六'][n],
    },
    formatLong: {
      ...locale.formatLong,
      month: (date) => {
        const year = date.getFullYear();
        const month = date.getMonth();
        const monthNames = [
          '一月', '二月', '三月', '四月', '五月', '六月',
          '七月', '八月', '九月', '十月', '十一月', '十二月'
        ];
        return `${monthNames[month]} ${year}`;
      },
    },
  };

  useEffect(() => {
    const formattedMinDate = queryTimeRange.min.toFormat('yyyy/MM/dd');
    const formattedMaxDate = queryTimeRange.max.toFormat('yyyy/MM/dd');
    // console.log('Formatted min date:', formattedMinDate);
    // console.log('Formatted max date:', formattedMaxDate);

    setFormattedDates(formattedMinDate, formattedMaxDate);
    
  }, [queryTimeRange, setFormattedDates]);

  useEffect(() => {
    const startDate = format(dateState.startDate, 'yyyy/MM/dd');
    const endDate = format(dateState.endDate, 'yyyy/MM/dd');
    setDisplayDateRange(`${startDate} ~ ${endDate}`);
  }, [dateState]);

  
  return (
    <Box
      sx={{
        width: '100%', 
        height: '72px',
        position: 'fixed',
        top: 0,
        zIndex: 1201,
        backgroundColor: 'white',
        boxShadow: '0 5px 10px 0 rgba(0, 0, 0, .05)',
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
        alignItems: 'center'
      }}
    >
      <ToggleButtonBox 
        open={open} 
        sx={{ 
          display: 'inline-flex',
          alignItems: 'center'
        }}
      >
        <IconButton
          sx={{
            // width: '25px',
            // height: '28px',
            overflow: 'hidden',
            background: '#FDEDF1',
            width: '56px',
            height: '72px',
            borderRadius: 0,
            // display: 'inline-block'
          }}
          onClick={drawerOnTrigger}
        >
          {open ? (
            <SvgIcon component={DrawerCloseIcon} inheritViewBox sx={{ fill: (theme) => theme.palette.neutral.grey }} />
          ) : (
            <SvgIcon component={DrawerOpenIcon} inheritViewBox sx={{ fill: (theme) => theme.palette.neutral.grey }} />
          )}
        </IconButton>
        <img 
          src={HeaderLogoIcon} 
          style={{
            display: 'inline-block',
            width: '172px',
            height: '48px',
            marginLeft: '26px',
            marginRight: '12px'
          }}
        />
        <Typography
          variant="h4"
          sx={{
            pl: '12px',
            fontWeight: 700,
            color: (theme) => theme.palette.neutral.grey,
            position: 'relative',
            '&:before': {
              content: '""',
              width: '2px',
              height: '24px',
              display: 'block',
              position: 'absolute',
              backgroundColor: (theme) => theme.palette.neutral.grey,
              left: 0, 
              top: 0,
              bottom: 0,
              margin: 'auto'
            }
          }}
        >
          陳情案件分析平台
        </Typography>
      </ToggleButtonBox>
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'row wrap',
          alignItems: 'center'
        }}
      >
        <Typography
          variant="h5"
          sx={{
            // pl: '16px',
            fontWeight: 'bold',
            mr: '10px'
          }}
        >
          日期區間
        </Typography>
        <Box 
          sx={{
            backgroundColor: (theme) => theme.palette.bg.grey,
            padding: '10px 8px 10px 20px',
            borderRadius: '99px',
            display: 'flex',
            flexFlow: 'row wrap',
            alignItems: 'center',
            mr: '10px',
            '&:hover': {
              cursor: 'pointer'
            }
          }}
          onClick={handleClick}
        >
          <Typography 
            variant="h5"
            sx={{ 
              fontWeight: '400',
              color: (theme) => theme.palette.neutral.silver,
            }}
          >
            {`${formattedMinDate} ~ ${formattedMaxDate}`}
          </Typography>
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.primary.main,
              display: 'inline-block',
              padding: '4px 8px',
              borderRadius: '99px',
              width: '36px',
              height: '28px',
              ml: '12px'
            }}
          >
            <SvgIcon 
              component={CalendarIcon} 
              inheritViewBox 
              sx={{ 
                fill: (theme) => theme.palette.bg.white,
                width: '100%',
                height: 'auto'
              }} 
            />
          </Box>
        </Box>
        <Popover
          id={id}
          open={openDatePicker}
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          sx={{
            zIndex: 99999,
            top: '12px',
            borderRadius: '8px',
            '.rdrDateDisplayWrapper': {
              backgroundColor: '#fff',
              width: '100%'
            },
            '.rdrNextPrevButton': {
              backgroundColor: '#fff'
            },
            '& .MuiPaper-root': {
              borderRadius: '8px',
            }
          }}
        >
          <Stack>
             <Box className="custom-date-range">
              <Box className="date-range-wrapper">
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    mb: '-10px'
                  }}
                >
                  <Box 
                    sx={{
                      backgroundColor: (theme) => theme.palette.bg.white,
                      border: '1px solid #E5E5E5',
                      padding: '12px 20px',
                      borderRadius: '8px',
                      display: 'flex',
                      flexFlow: 'row wrap',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      mr: '12px',
                      width: '364px'
                    }}
                  >
                    <Typography 
                      variant="h5"
                      sx={{ 
                        fontWeight: 400,
                        color: (theme) => theme.palette.neutral.dark,
                      }}
                    >
                      {`${displayDateRange}`}
                    </Typography>
                    <SvgIcon 
                      component={CalendarIcon} 
                      inheritViewBox 
                      sx={{ 
                        fill: (theme) => theme.palette.neutral.grey,
                        width: '20px',
                        height: '20px'
                      }} 
                    />
                  </Box>
                  <StyledDateRangePickerButton
                    onClick={handleResetDate}
                    sx={{
                      backgroundColor: '#fff',
                      border: '1px solid #F4A4B8',
                      fontWeight: 400,
                      width: 'auto',
                      p: '12px 20px',
                      m: 0,
                      '&:hover': {
                        backgroundColor: '#FDEDF1'
                      },
                      '&:focus': {
                        backgroundColor: '#FDEDF1'
                      }
                    }}
                  >
                    <ResetDateIcon style={{ marginRight: '4px' }} />
                    重設
                  </StyledDateRangePickerButton>
                </Box>
                <DateRangePicker
                  // editableDateInputs={true}
                  maxDate={DateTime.now().endOf('day').plus({ days: -1 }).toJSDate()}
                  minDate={DateTime.local(2021, 1, 1).toJSDate()}
                  onChange={handleSelect}
                  locale={customLocale}
                  moveRangeOnFirstSelection={false}
                  ranges={[dateState]}
                  staticRanges={createStaticRanges(dateRangePickerRanges)}
                  months={2}
                  inputRanges={[]}
                  direction="horizontal"
                  rangeColors={['#FDEDF1', '#FDEDF1', '#E95076']}
                  showDateDisplay={false}
                  disabledDay={(date) => subDays(new Date(), 1) < date}
                />
              </Box>
              <Box className="date-range-buttons">
                <StyledDateRangePickerButton
                  sx={{
                    backgroundColor: '#fff',
                  }}
                  onClick={() => setAnchorEl(null)}
                >
                  取消
                </StyledDateRangePickerButton>
                <StyledDateRangePickerButton
                  onClick={handleSearch}
                >
                  確認
                </StyledDateRangePickerButton>
              </Box>
            </Box>
          </Stack>
        </Popover>
        <ExportWeekReport />
      </Box>
    </Box>
  )
}

export default Header