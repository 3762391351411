import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
	Button,
	IconButton,
	OutlinedInput,
	Typography,
	InputLabel,
	InputAdornment,
	FormControl,
	Box,
	Stack,
	Alert,
} from "@mui/material";
import Fallback from "@/components/Fallback";
import { styled } from "@mui/material/styles";
import ReCAPTCHA from "react-google-recaptcha";
import { login } from '@/api/loginAPI'
import StyledAlert from '@/components/StyledAlert'

import TycgLogo from "@/assets/tycgLogo.svg";
import { ReactComponent as EyeOpenIcon } from "@/assets/eye.svg";
import { ReactComponent as EyeCloseIcon } from "@/assets/eyeClose.svg";

const StyledIconButton = styled(IconButton)({
	svg: {
		width: "20px",
		height: "20px",
	},
});

const StyledRecaptcha = styled(ReCAPTCHA)({
	"&.g-recaptcha": {
		transform: "scale(0.8)",
	},
});

const StyledButton = styled(Button)({
	width: "240px",
	marginTop: "10px",
});

function Login() {
	const [recaptchaToken, setRecaptchaToken] = useState("");
	const [isLoginLoading, setIsLoginLoading] = useState(false);
	const [account, setAccount] = useState("");
	const [passwordState, setPasswordState] = useState({
		password: "",
		showPassword: false,
	});
	const [errorMsg, setErrorMsg] = useState("");
	const [showAlert, setShowAlert] = useState(false);

	const navigate = useNavigate();
	const thisYear = new Date().getFullYear();

	useEffect(() => {
		if (errorMsg) {
			setTimeout(() => {
				setShowAlert(false);
				setErrorMsg("");
			}, 2000);

			setShowAlert(true);
		}
	}, [errorMsg]);

	useEffect(() => {
		localStorage.removeItem('accessToken');
	}, [])

	const handleClickShowPassword = () => {
		setPasswordState({
			...passwordState,
			showPassword: !passwordState.showPassword,
		});
	};

	const onChangeRecaptchaToken = (token) => {
		setRecaptchaToken(token);
	};

	const handleOnLogin = async () => {
		// check account and password
		if (!account) {
			setErrorMsg("帳號不得為空");
			return;
		} else if (!passwordState.password) {
			setErrorMsg("密碼不得為空");
			return;
		} else if (!recaptchaToken) {
			setErrorMsg("請勾選我不是機器人核取方塊");
			return;
		}

		if (errorMsg) {
			setIsLoginLoading(false);
		} else {
			setIsLoginLoading(true);
			try {
				await login(account, passwordState.password);
				setIsLoginLoading(false);
				navigate('/dashboard');
			} catch (error) {
				if (error.response.status === 400){
					setErrorMsg("帳號或密碼錯誤")
					setIsLoginLoading(false);
				}
			}
		}
	};

	return (
		<>
			{isLoginLoading && <Fallback />}
			<Box
				component="section"
				sx={{
					padding: 0,
					mt: '8rem',
				}}
			>
				<Box
					sx={{
						textAlign: "center",
						display: "flex",
						flexFlow: "column wrap",
						alignItems: "center",
					}}
				>
					{/* logo */}
					<Box
						component="img"
						src={TycgLogo}
						alt="Taoyuan city government logo"
						width={140}
						height={203}
						loading="lazy"
					/>
					<Typography
						variant="h3"
						sx={{
							color: '#52697A',
							fontWeight: 700,
							marginTop: "40px",
							marginBottom: "5px",
						}}
					>
						陳情案件分析平台
					</Typography>
					<Typography
						variant="h4"
						sx={{
							color: (theme) => theme.palette.neutral.grey,
							marginBottom: "20px",
							fontWeight: 400
						}}
					>
						歡迎回來！請登入你的帳號密碼
					</Typography>

					{/* login form */}
					<FormControl sx={{ marginBottom: "19px" }}>
						<OutlinedInput
							onChange={(e) => setAccount(e.target.value)}
							value={account}
							placeholder="帳號"
						/>
					</FormControl>

					<FormControl
						sx={{ marginBottom: "13px" }}
						variant="outlined"
					>
						<OutlinedInput
							id="outlined-adornment-password"
							type={
								passwordState.showPassword ? "text" : "password"
							}
							value={passwordState.password}
							placeholder="密碼"
							onChange={(e) =>
								setPasswordState({
									...passwordState,
									password: e.target.value,
								})
							}
							endAdornment={
								<InputAdornment position="end">
									<StyledIconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
										edge="end"
										className="eye-icon"
									>
										{passwordState.showPassword ? (
											<EyeOpenIcon />
										) : (
											<EyeCloseIcon />
										)}
									</StyledIconButton>
								</InputAdornment>
							}
						/>
					</FormControl>
					<StyledRecaptcha
						className="g-recaptcha"
						sitekey="6LdMrbopAAAAAO3V-fLHWUxJI0QjXZiL4e27FjTD"
						onChange={onChangeRecaptchaToken}
						size="normal"
					/>
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
						}}
					>
						<StyledButton
							onClick={handleOnLogin}
							disabled={isLoginLoading}
						>
							登入
						</StyledButton>
					</Box>
					<Box
						sx={{
							mt: '26px'
						}}
					>
						<Link 
							to={`/user/password/forgot`}
							style={{
								textDecoration: 'none'
							}}
						>
							<Typography
								sx={{
									color: '#0284C7',
									fontSize: '12px',
									lineHeight: '14px',
									fontWeight: 400
								}}
							>
								忘記密碼?
							</Typography>
						</Link>
					</Box>
				</Box>
			</Box>

			{/* footer copyright */}
			<Box
				sx={{
					textAlign: "center",
					mt: '10.4rem'
				}}
			>
				<Typography
					variant="h5"
					sx={{ 
						color: (theme) => theme.palette.neutral.dark ,
						fontWeight: 400
					}}
				>
					© {thisYear} Taoyuan City Government. All rights reserved.
				</Typography>
			</Box>

			{/* error message alert */}
			{showAlert && (
				<StyledAlert severity="error">{errorMsg}</StyledAlert>
			)}
		</>
	);
}

export default Login;
