import { createTheme } from '@mui/material'

const customTheme = createTheme({
  palette: {
    // pink
    primary: {
      main: '#E95076',
      dark: '#E92153',
      light: '#FDEDF1'
    },
    // gray
    neutral: {
      dark: '#0A0A0A',
      silver: '#404040',
      grey: '#737373',
      light: '#D4D4D4'
    },
    function: {
      error: '#E95050',
      success: '#23BFAA',
      warn: '#FBBF24',
      info: '#0284C7'
    },
    info: {
      main: '#5084E9',
      second: '#E95050'
    },
    bg: {
      dark: '#E5E5E5',
      grey: '#F5F5F5',
      light: '#FAFAFA',
      white: '#FFFFFF'
    },
    dashboard: {
			wordCloud: {
				colors: [
					"#1e6ebf",
					"#4570a2",
					"#b7c994",
					"#ccb498",
					"#9ec6eb",
					"#72859c",
					"#87a0af",
					"#909ba1",
					"#abbbc8",
					"#b5c1cc",
				],
				default: "#A0A7BC",
			},
		},
  },
  typography: {
    h3: {
      fontFamily: '"Work Sans", "Noto Sans TC", sans-serif',
      fontWeight: '700',
      fontSize: '28px',
      lineHeight: '42px',
    },
    h4: {
      fontFamily: '"Work Sans", "Noto Sans TC", sans-serif',
      fontWeight: '700',
      fontSize: '20px',
      lineHeight: '30px',
    },
    h5: {
      fontFamily: '"Work Sans", "Noto Sans TC", sans-serif',
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: '21px',
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderColor: '#e5e5e5',
          color: '#737373',
          padding: '12px 20px',
          borderRadius: 8,
          width: '240px',
          '&:hover': {
            background: '#FAFAFA',  
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: '#e5e5e5',
            }
          },
        },
        input: {
          width: '100%',
          padding: 0,
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '21px',
          border: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          width: '120px',
          background: '#E95076',
          color: '#FAFAFA',
          padding: '8px 20px',
          fontSize: '14px',
          lineHeight: '21px',
          fontWeight: 700,
          borderRadius: 99,
          transition: 'ease-in-out .3s',
          '&:hover': {
            background: '#E92153'
          },
          '&:focus': {
            background: '#E92153'
          }
        }
      }
    },
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          '&:hover': {
            sortIcon: {
              opacity: 0
            },
          }
        },
        iconButtonContainer: {
          visibility: 'hidden',
          width: '0',
        },
        sortIcon: {
          opacity: 0
        },
        iconButtonContainer: {
          display: 'none',
          visibility: 'hidden',
          width: '0',
        },
      },
    },
  }
})

export default customTheme