import { create } from 'zustand';

const UseOwnerUnitStore = create((set) => ({
  subOwnerUnitsSelected: {},
  // 更新選取一級承辦機關狀態
  updateOwnerUnitSelected: (isChecked, ownerUnit, caseData) => set((state) => {
    if (isChecked) {
      return {
        subOwnerUnitsSelected: {
          ...state.subOwnerUnitsSelected,
          [ownerUnit]: caseData?.owner_unit?.owner_unit_hierarchy[ownerUnit]
        }
      }
    } else {
      const { [ownerUnit]: _, ...rest } = state.subOwnerUnitsSelected
      return {
        subOwnerUnitsSelected: rest
      }
    }
  }),
  // 更新選取二級承辦機關狀態
  updateSubOwnerUnitSelected: (ownerUnit, subOwnerUnit, isChecked) => set((state) => {
    const prevSelected = state.subOwnerUnitsSelected[ownerUnit] || []

    const updatedSelection = isChecked
    ? [...prevSelected, subOwnerUnit]
    : prevSelected.filter((item) => item !== subOwnerUnit);

    // 若取消選擇二級機關的唯一項目時，則移除相應的一級機關
    if (!updatedSelection.length) {
      const { [ownerUnit]: _, ...rest } = state.subOwnerUnitsSelected;
      return {
        subOwnerUnitsSelected: rest
      }
    }

    return {
      subOwnerUnitsSelected: {
        ...state.subOwnerUnitsSelected,
        [ownerUnit]: updatedSelection
      }
    }
  }),
  // 取消選取機關
  deleteOwnerUnitSelected: (chipToDelete) => set((state) => {
    const updated = { ...state.subOwnerUnitsSelected };
    Object.keys(updated).forEach((key) => {
      updated[key] = updated[key].filter((sub) => sub !== chipToDelete.replace(`${key}/`, ''));
      if (updated[key].length === 0) {
        delete updated[key];
      }
    });
    return {
      subOwnerUnitsSelected: updated
    }
  }),
  // 清空已選取的機關
  clearOwnerUnitSelected: () => { 
    set({ subOwnerUnitsSelected: {} })
  }
}));

export default UseOwnerUnitStore;