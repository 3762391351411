import { useState } from 'react'
import { addDays, isBefore, isAfter } from 'date-fns'
import { DateTime } from 'luxon';

const useDateRangePicker = ({
  startDate: sd, endDate: ed, minInterval = 0, onSearch = () => {},
}) => {
  const [dateState, setDateState] = useState({
    startDate: sd,
    endDate: ed,
    key: 'selection',
  })

  const handleSelect = (ranges) => {
    if (!ranges) return
    const { selection } = ranges
    const { startDate, endDate } = selection
    
    const newEnd = addDays(startDate, minInterval)
    const maxSelectableDate = DateTime.now().endOf('day').plus({ days: -1 }).toJSDate();

    // 選取範圍不限制，結束日最多可選到前一天
    if (endDate > maxSelectableDate) {
      setDateState((prev) => ({ ...prev, startDate, endDate: maxSelectableDate }));
    } else if (isBefore(addDays(startDate, minInterval), endDate)) {
      setDateState((prev) => ({ ...prev, startDate, endDate }))
    } else {
      setDateState((prev) => ({ ...prev, startDate, endDate: newEnd }))
    }
  }

  const handleSearch = () => {
    const validStartDate = isAfter(new Date(dateState.endDate), new Date())
      ? addDays(new Date(dateState.startDate), -2)
      : new Date(dateState.startDate)
    const validEndDate = isAfter(new Date(dateState.endDate), new Date())
      ? addDays(new Date(), -1)
      : new Date(dateState.endDate)

    onSearch({ validStartDate, validEndDate })
  }

  const handleDateChange = ({ startDate: queryStartDate, endDate: queryEndDate }) => {
    setDateState((prev) => ({
      ...prev,
      startDate: queryStartDate,
      endDate: queryEndDate,
    }))
  }

  return {
    dateState,
    handleSearch,
    handleSelect,
    handleDateChange,
  }
}

export default useDateRangePicker
